import { computed, defineComponent, watch, ref } from 'vue';
import { isNil } from 'lodash-es';
import { useField } from 'vee-validate';
import Image2 from '@hems/component/src/image/Image2.vue';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper } from '@hems/util';
import { INPUT_CLASS_NAME, INPUT_TYPE, INPUT_VALID_CLASS, INPUT_VALIDATE_MODE } from '@hems/util/src/constant/input';
export default defineComponent({
    name: 'BasicInput',
    components: {
        ErrorMessageLabel,
        Image2,
    },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: [String, Number],
            required: true,
        },
        validateMode: {
            type: String,
            default: INPUT_VALIDATE_MODE.INPUT,
        },
        className: {
            type: String,
            default: INPUT_CLASS_NAME.SMALL,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        as: {
            type: Function,
            default: null,
        },
        unit: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: INPUT_TYPE.TEXT,
        },
        validClass: {
            type: String,
            default: INPUT_VALID_CLASS.DEFAULT,
        },
    },
    emits: ['update:modelValue', 'change', 'blur'],
    setup(props, { emit }) {
        const inputName = props.name ?? Helper.getUUID();
        const { value: inputValue, errorMessage } = useField(inputName, undefined, {
            initialValue: props.modelValue,
            type: props.type,
        });
        const displayValue = computed(() => {
            const value = props.as ? props.as() : Helper.nullTo(props.modelValue, '');
            return `${value} ${!Helper.isNull(value) ? props.unit || '' : ''}`;
        });
        const showPassword = ref(false);
        const setValue = (value) => {
            if (props.type === INPUT_TYPE.NUMBER) {
                if (isNil(value)) {
                    emit('update:modelValue', null);
                    return;
                }
                emit('update:modelValue', Number(value));
                return;
            }
            emit('update:modelValue', value);
        };
        const onBlur = (e) => {
            if (props.validateMode === INPUT_VALIDATE_MODE.BLUR && e.target instanceof HTMLInputElement) {
                setValue(e.target.value);
            }
            emit('blur');
        };
        const onChange = (e) => {
            if (props.validateMode === INPUT_VALIDATE_MODE.INPUT && e.target instanceof HTMLInputElement) {
                setValue(e.target.value);
                emit('change');
            }
        };
        const displayPassword = (value) => {
            if (isNil(value))
                return value;
            return Array.from(Array(value.length), (_, index) => (index > 2 ? '*' : value.substring(index, 1))).join('');
        };
        watch(() => props.modelValue, () => {
            if (props.modelValue !== inputValue.value)
                inputValue.value = props.modelValue ?? null;
        });
        return {
            inputValue,
            inputName,
            errorMessage,
            Helper,
            INPUT_TYPE,
            displayValue,
            showPassword,
            INPUT_VALID_CLASS,
            displayPassword,
            onBlur,
            onChange,
        };
    },
});
