/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import range from 'lodash-es/range';
import moment from 'moment';
import { DAYS_OF_WEEK, ONE_DAY_VALUE_IN_HOUR, ONE_DAY_VALUE_IN_MINUTES, ONE_MINUTE_VALUE_IN_SECONDS, } from '@hems/util/src/constant';
import { END_TIME_END_HOUR, MINUTE_LIST_STEP, SCHEDULE_EVENT_TYPE, START_MINUTE, TOU_OPERATION, } from '@hems/util/src/constant/energyManagement';
import { getAddedDate, startOfWeek, getDateWithAdjustedTime } from '@hems/util/src/helper/dateHelper';
import { isOverlapping, padLeft } from '@hems/util/src/helper/helper';
const dayOfWeekList = Object.values(DAYS_OF_WEEK);
export const getScheduleEventByDayOfWeekOfOperation = (operation, timeValues) => operation.daysOfWeek.map((daysOfWeek) => {
    const dayOrder = dayOfWeekList.findIndex((day) => day === daysOfWeek);
    const baseDate = getAddedDate(startOfWeek, dayOrder);
    return {
        id: operation.operationId,
        start: getDateWithAdjustedTime(baseDate, {
            hour: Number(timeValues.startHour),
            minute: Number(timeValues.startMinute),
        }),
        end: getDateWithAdjustedTime(baseDate, {
            hour: Number(timeValues.endHour),
            minute: Number(timeValues.endMinute),
        }),
        eventType: SCHEDULE_EVENT_TYPE.DEFAULT,
    };
});
export const getOperationTimeFromStartAndEndTime = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(':');
    const [endHour, endMinute] = endTime.split(':');
    return {
        startHour,
        startMinute,
        endHour,
        endMinute,
    };
};
export const createScheduleEventByOperation = (operations) => operations
    .map((operation) => getScheduleEventByDayOfWeekOfOperation(operation, getOperationTimeFromStartAndEndTime(operation.startTime, operation.endTime)))
    .flat();
export const initialOperationTime = {
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
};
export const getTimeStringWithOperationTime = ({ startHour, startMinute, endHour, endMinute }) => ({
    startTime: `${startHour}:${startMinute}`,
    endTime: `${endHour}:${endMinute}`,
});
export const isDuplicatedScheduleEvent = (currentScheduleEventList, temporaryScheduleEvent) => currentScheduleEventList.some((scheduleEvent) => isOverlapping(scheduleEvent.start, scheduleEvent.end, temporaryScheduleEvent.start, temporaryScheduleEvent.end));
const initialOperationCountMap = {
    [DAYS_OF_WEEK.SUNDAY]: 0,
    [DAYS_OF_WEEK.MONDAY]: 0,
    [DAYS_OF_WEEK.TUESDAY]: 0,
    [DAYS_OF_WEEK.WEDNESDAY]: 0,
    [DAYS_OF_WEEK.THURSDAY]: 0,
    [DAYS_OF_WEEK.FRIDAY]: 0,
    [DAYS_OF_WEEK.SATURDAY]: 0,
};
export const calculateEachDayOperation = (dayList) => {
    return dayList.reduce((dayMap, day) => {
        dayMap[day] = dayMap[day] + 1;
        return dayMap;
    }, { ...initialOperationCountMap });
};
export const convertWeeklyScheduleEventToDayCountMap = (operationId, scheduleEventList) => {
    const scheduleEventDayList = scheduleEventList
        .filter((scheduleEvent) => scheduleEvent.id !== operationId)
        .map((scheduleEvent) => scheduleEvent.start instanceof Date
        ? dayOfWeekList[scheduleEvent.start.getDay()]
        : dayOfWeekList[moment(scheduleEvent.start).day()]);
    return calculateEachDayOperation(scheduleEventDayList);
};
const baseOperationInfo = {
    operationId: '',
    startTime: '',
    endTime: '',
    daysOfWeek: [...dayOfWeekList],
    operation: TOU_OPERATION.CHARGE_FROM_SOLAR,
};
const dailyTimeSlots = range(0, ONE_DAY_VALUE_IN_MINUTES, MINUTE_LIST_STEP).map((minutes) => {
    const startHour = Math.floor(minutes / ONE_MINUTE_VALUE_IN_SECONDS);
    const startMinute = minutes % ONE_MINUTE_VALUE_IN_SECONDS;
    const endFullMinutes = minutes + MINUTE_LIST_STEP;
    let endHour = Math.floor(endFullMinutes / ONE_MINUTE_VALUE_IN_SECONDS) % ONE_DAY_VALUE_IN_HOUR;
    let endMinute = endFullMinutes % ONE_MINUTE_VALUE_IN_SECONDS;
    if (minutes === ONE_DAY_VALUE_IN_MINUTES - MINUTE_LIST_STEP) {
        endHour = END_TIME_END_HOUR;
        endMinute = START_MINUTE;
    }
    return {
        startHour: padLeft(startHour, 2),
        startMinute: padLeft(startMinute, 2),
        endHour: padLeft(endHour, 2),
        endMinute: padLeft(endMinute, 2),
    };
});
export const quarterHourScheduleList = dailyTimeSlots
    .map((timeValues) => getScheduleEventByDayOfWeekOfOperation(baseOperationInfo, timeValues))
    .flat();
export const isFullySetOperations = (currentScheduleEventList) => quarterHourScheduleList.every((scheduleEvent) => isDuplicatedScheduleEvent(currentScheduleEventList, scheduleEvent));
