// Copyright © 2024 Qcells. All rights reserved.
// This software is proprietary and confidential. Unauthorized use,
// duplication, or distribution of software is strictly prohibited.
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRole } from '@hems/util/src/composable/role';
// eslint-disable-next-line import/prefer-default-export
export const useSiteId = () => {
    const store = useStore();
    const userStore = useStore();
    const { isUser } = useRole();
    const siteId = computed(() => (isUser ? userStore.state.site.siteId : store.state.siteNew.siteId));
    return { siteId };
};
