import { defineComponent, reactive, onMounted, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import pickBy from 'lodash-es/pickBy';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, BasicInput, ToggleInput, Selector, SettingTable, SettingTableRow, useMessageBox, ChangedMark, } from '@hems/component';
import { CommonService } from '@hems/service';
import { PCS_CONNECTION_MODE, EXTERNAL_EMS_CONNECTION, EXTERNAL_EMS_MODEL } from '@hems/util/src/constant';
import { isNull, codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';
import { getEngineerSettingPvModuleGen3SmartModuleGen2Config } from './config';
import SettingConfirmPopup from '../SettingConfirmPopup.vue';
const initValue = {
    eos_flag: 0,
    sg_ready_flag: 0,
    fcas_flag: 0,
    fcas_test_mode: 0,
    pcs_connection_mode: 0,
    pcs_debug_mode: 0,
    external_ems_model: undefined,
    external_ems_modbus_rtu_dev: '/dev/ttymxc0',
    debug_mode_modbus: 0,
    auto_fw_update_flag: 0,
};
export default defineComponent({
    name: 'EngineerSettingsPvModuleFoxSharedContainer',
    components: {
        Accordion,
        BasicInput,
        ToggleInput,
        Form,
        Selector,
        SettingTable,
        SettingTableRow,
        ChangedMark,
        SettingConfirmPopup,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        productModelNm: {
            type: String,
            required: true,
        },
        cntryCd: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: Boolean,
        isHcore: {
            type: Boolean,
            default: false,
        },
        isQcore: {
            type: Boolean,
            default: false,
        },
        isAcCoupled: {
            type: Boolean,
            default: false,
        },
        isFcas: {
            type: Boolean,
            default: false,
        },
        genType: {
            type: String,
            default: 'FOX_ESS',
        },
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
        siteId: {
            type: Number,
            default: null,
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    async setup(props, { emit }) {
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const commonService = new CommonService(window.axiosInstance.axios);
        let copyData = cloneDeep(props.data);
        const copyChangedValueSet = cloneDeep(props.changedValueSet);
        const state = reactive({
            data: { ...initValue, ...props.data },
            changedValueSet: cloneDeep(props.changedValueSet),
            confirmPopup: { on: false, data: [] },
            gridCodeOptions: [],
        });
        const isEditable = ref(props.editable);
        const isSetFCAS = ref(props.isFcas);
        const isHCORE = ref(props.isHcore);
        const isQCORE = ref(props.isQcore);
        const isACCoupled = ref(props.isAcCoupled);
        const selectorOptionsState = reactive({
            pcsConnectionType: [],
            modbusRtuDev: [],
            modbusRtuBaudrate: [],
            modbusRtuParity: [],
            extEmsModel: [],
            debugInfo: [],
            filteredPcsConnectionType: [],
            meterInfo: [],
            gridCodeInfo: [],
        });
        const isDisabledEMSModel = state.data.external_ems_model == EXTERNAL_EMS_MODEL.FOXESS_H1 ||
            state.data.external_ems_model == EXTERNAL_EMS_MODEL.FOXESS_H3;
        const isDisabledEMSModBus = !isEditable.value || isDisabledEMSModel;
        const isDisabledEMSConnection = !isEditable.value || (state.data.pcs_connection_mode === 1 && isDisabledEMSModel);
        const computedVal = {
            valueRange: {
                pv_feed_in_limit: { min: 0, max: 100 },
                pv_max_pwr2: { min: 0, max: 3300 },
                feed_in_limit: { min: 0, max: 100 },
                battery_backup_soc: { min: 0, max: 100 },
            },
        };
        const defaultValidate = yup
            .number()
            .transform((v, o) => (o === '' ? null : v))
            .nullable();
        const schema = yup.object().shape({
            eos_flag: defaultValidate,
            eos_power_sampling_interval: defaultValidate.integer(),
            eos_default_sampling_interval: defaultValidate.integer(),
            sg_ready_flag: defaultValidate,
            sg_ready_heat_pump_consumption_power: yup.number().when('sg_ready_flag', {
                is: (sg_ready_flag) => sg_ready_flag === 1,
                then: defaultValidate.integer().min(0).max(65000),
            }),
            fcas_response_capacity_limit: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.integer().min(0).max(2000),
            }),
            fcas_droop_rate_raise: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(0).max(100).unitNumber(0.01),
            }),
            fcas_droop_rate_lower: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(0).max(100).unitNumber(0.01),
            }),
            fcas_oftb_max: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(45).max(65).unitNumber(0.01),
            }),
            fcas_oftb_min: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(45).max(65).unitNumber(0.01),
            }),
            fcas_fr_max: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(45).max(65).unitNumber(0.01),
            }),
            fcas_fr_min: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(45).max(65).unitNumber(0.01),
            }),
            fcas_nofb_max: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(45).max(65).unitNumber(0.01),
            }),
            fcas_nofb_min: yup
                .number()
                .nullable()
                .when('eos_flag', {
                is: (eos_flag) => isSetFCAS.value && eos_flag === 1,
                then: defaultValidate.min(45).max(65).unitNumber(0.01),
            }),
            pcs_connection_mode: defaultValidate.integer(),
            pcs_debug_mode: defaultValidate.integer(),
            external_ems_model: defaultValidate.integer(),
            external_ems_connection: defaultValidate.integer().min(0).max(3),
            external_ems_modbus_tcp_ip: yup.string().when({
                is: (pcs_connection_mode, external_ems_connection, external_ems_modbus_tcp_ip) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP &&
                    !isNull(external_ems_modbus_tcp_ip),
                then: yup
                    .string()
                    .matches(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, t('message.format_ipv4')),
            }),
            external_ems_modbus_tcp_port: defaultValidate.when({
                is: (pcs_connection_mode, external_ems_connection) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP,
                then: defaultValidate.integer().min(0).max(65535),
            }),
            external_ems_modbus_rtu_dev: yup
                .string()
                .nullable()
                .when({
                is: (pcs_connection_mode, external_ems_connection) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
                then: yup.string().nullable(),
            }),
            external_ems_modbus_rtu_baudrate: defaultValidate.when({
                is: (pcs_connection_mode, external_ems_connection) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
                then: defaultValidate.integer(),
            }),
            external_ems_modbus_rtu_parity: yup
                .string()
                .nullable()
                .when({
                is: (pcs_connection_mode, external_ems_connection) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
                then: yup.string().nullable(),
            }),
            external_ems_modbus_rtu_data: defaultValidate.when({
                is: (pcs_connection_mode, external_ems_connection) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
                then: defaultValidate.integer().min(5).max(8),
            }),
            external_ems_modbus_rtu_stop: defaultValidate.when({
                is: (pcs_connection_mode, external_ems_connection) => pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
                then: defaultValidate.integer().min(1).max(2),
            }),
            external_ems_server_id: defaultValidate.integer(),
            battery_user_soc_min: defaultValidate.integer().min(0).max(yup.ref('battery_user_soc_max')),
            battery_user_soc_max: defaultValidate.integer().min(yup.ref('battery_user_soc_min')).max(100),
            log_level_process_mgr: defaultValidate,
            log_level_system_log: defaultValidate,
            log_level_fota: defaultValidate,
            log_level_power_control: defaultValidate,
            log_level_algorithm_mgr: defaultValidate,
            log_level_ess_mgr: defaultValidate,
            log_level_dc_source_mgr: defaultValidate,
            log_level_cloud_mgr: defaultValidate,
            log_level_meter_mgr: defaultValidate,
            log_level_gateway_mgr: defaultValidate,
            log_level_data_accumulator: defaultValidate,
            log_level_db_mgr: defaultValidate,
            log_level_web_engine: defaultValidate,
            log_level_gqa: defaultValidate,
            log_level_mlpe_mgr: defaultValidate,
            log_level_sys_mgr: defaultValidate,
            log_level_energy_link: defaultValidate,
            log_level_csip_mgr: defaultValidate,
            log_level_energy_optimizer: defaultValidate,
            debug_mode_mqtt: defaultValidate,
            debug_mode_modbus: defaultValidate,
            auto_fw_update_flag: defaultValidate.integer(),
        });
        async function loadSelectorOptions() {
            const { PCS_CONNECTION_TYPE_GEN3_CD, MODBUS_RTU_BAUDRATE_GEN3_CD, MODBUS_RTU_PARITY_GEN3_CD, EXT_EMS_MODEL_GEN3_CD, DEBUGINFO_GEN3_CD, EXTERNAL_EMS_GRID_CODE, } = await commonService.getCodesByGroupCode([
                { grpCd: 'PCS_CONNECTION_TYPE_GEN3_CD' },
                { grpCd: 'MODBUS_CONNECTION_TYPE_GEN3_CD' },
                { grpCd: 'MODBUS_RTU_BAUDRATE_GEN3_CD' },
                { grpCd: 'MODBUS_RTU_PARITY_GEN3_CD' },
                { grpCd: 'MODE_FLAG_CD' },
                { grpCd: 'EXT_EMS_MODEL_GEN3_CD', exclude: ['2'] },
                { grpCd: 'DEBUGINFO_GEN3_CD' },
                { grpCd: 'EXTERNAL_EMS_GRID_CODE' },
            ]);
            selectorOptionsState.pcsConnectionType = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(PCS_CONNECTION_TYPE_GEN3_CD, t),
            ];
            selectorOptionsState.modbusRtuBaudrate = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(MODBUS_RTU_BAUDRATE_GEN3_CD, t),
            ];
            selectorOptionsState.modbusRtuParity = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(MODBUS_RTU_PARITY_GEN3_CD, t),
            ];
            selectorOptionsState.extEmsModel = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(EXT_EMS_MODEL_GEN3_CD, t),
            ];
            selectorOptionsState.debugInfo = [
                {
                    text: t('common.select'),
                    value: null,
                },
                ...codeNamesToSelectorOptions(DEBUGINFO_GEN3_CD, t),
            ];
            selectorOptionsState.gridCodeInfo = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(EXTERNAL_EMS_GRID_CODE, t),
            ];
            selectorOptionsState.modbusRtuDev = [
                { text: 'Serial1 (/dev/ttymxc3)', value: '/dev/ttymxc3' },
                { text: 'Serial2 (/dev/ttymxc0)', value: '/dev/ttymxc0' },
                // { text: 'Serial3 (/dev/ttymxc1)', value: '/dev/ttymxc1' }, // 추후 변경될 가능성이 있어 주석 처리 조치
            ];
            selectorOptionsState.meterInfo = [
                { text: 'Disable', value: '0' },
                { text: 'Meter', value: '1' },
                { text: 'CT', value: '2' },
            ];
            selectorOptionsState.filteredPcsConnectionType = [
                { text: t('common.select'), value: null },
                ...codeNamesToSelectorOptions(PCS_CONNECTION_TYPE_GEN3_CD.filter((item) => item.code !== '2' && item.code !== '3'), t),
            ];
        }
        async function getFcasDefault() {
            if (!isNull(props.siteId)) {
                const fcasDefaultValue = await commonService.getFcasSettingDefault(props.siteId);
                return omit(fcasDefaultValue, 'fcas_flag');
            }
            return {};
        }
        function getParam() {
            const { eos_flag, eos_power_sampling_interval, eos_default_sampling_interval, sg_ready_flag, sg_ready_heat_pump_consumption_power, fcas_flag, fcas_response_capacity_limit, fcas_droop_rate_raise, fcas_droop_rate_lower, fcas_oftb_max, fcas_oftb_min, fcas_fr_max, fcas_fr_min, fcas_nofb_max, fcas_nofb_min, fcas_test_mode, pcs_connection_mode, pcs_debug_mode, external_ems_model, external_ems_connection, external_ems_modbus_tcp_ip, external_ems_modbus_tcp_port, external_ems_modbus_rtu_dev, external_ems_modbus_rtu_baudrate, external_ems_modbus_rtu_parity, external_ems_modbus_rtu_data, external_ems_modbus_rtu_stop, external_ems_server_id, external_ems_grid_code, 
            // external_ems_meter1,
            external_ems_meter2, battery_user_soc_min, battery_user_soc_max, log_level_process_mgr, log_level_system_log, log_level_fota, log_level_power_control, log_level_algorithm_mgr, log_level_ess_mgr, log_level_dc_source_mgr, log_level_cloud_mgr, log_level_meter_mgr, log_level_gateway_mgr, log_level_data_accumulator, log_level_db_mgr, log_level_web_engine, log_level_gqa, log_level_mlpe_mgr, log_level_sys_mgr, log_level_energy_link, log_level_diag_monitor, log_level_csip_mgr, log_level_energy_optimizer, debug_mode_mqtt, debug_mode_modbus, auto_fw_update_flag, } = state.data;
            const param = {
                eos_flag,
                eos_power_sampling_interval,
                eos_default_sampling_interval,
                sg_ready_flag,
                sg_ready_heat_pump_consumption_power: sg_ready_flag === 1 ? sg_ready_heat_pump_consumption_power : undefined,
                fcas_flag: isSetFCAS.value ? (eos_flag === 1 ? fcas_flag : 0) : undefined,
                fcas_response_capacity_limit: isSetFCAS.value && eos_flag === 1 ? fcas_response_capacity_limit : undefined,
                fcas_droop_rate_raise: isSetFCAS.value && eos_flag === 1 ? fcas_droop_rate_raise : undefined,
                fcas_droop_rate_lower: isSetFCAS.value && eos_flag === 1 ? fcas_droop_rate_lower : undefined,
                fcas_oftb_max: isSetFCAS.value && eos_flag === 1 ? fcas_oftb_max : undefined,
                fcas_oftb_min: isSetFCAS.value && eos_flag === 1 ? fcas_oftb_min : undefined,
                fcas_fr_max: isSetFCAS.value && eos_flag === 1 ? fcas_fr_max : undefined,
                fcas_fr_min: isSetFCAS.value && eos_flag === 1 ? fcas_fr_min : undefined,
                fcas_nofb_max: isSetFCAS.value && eos_flag === 1 ? fcas_nofb_max : undefined,
                fcas_nofb_min: isSetFCAS.value && eos_flag === 1 ? fcas_nofb_min : undefined,
                fcas_test_mode: isSetFCAS.value && eos_flag === 1 ? fcas_test_mode : undefined,
                pcs_connection_mode,
                pcs_debug_mode,
                external_ems_model: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL ? external_ems_model : undefined,
                external_ems_connection,
                external_ems_modbus_tcp_ip: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP
                    ? external_ems_modbus_tcp_ip
                    : undefined,
                external_ems_modbus_tcp_port: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP
                    ? external_ems_modbus_tcp_port
                    : undefined,
                external_ems_modbus_rtu_dev: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
                    ? external_ems_modbus_rtu_dev || '/dev/ttymxc0'
                    : undefined,
                external_ems_modbus_rtu_baudrate: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
                    ? external_ems_modbus_rtu_baudrate
                    : undefined,
                external_ems_modbus_rtu_parity: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
                    ? external_ems_modbus_rtu_parity
                    : undefined,
                external_ems_modbus_rtu_data: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
                    ? external_ems_modbus_rtu_data
                    : undefined,
                external_ems_modbus_rtu_stop: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
                    external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
                    ? external_ems_modbus_rtu_stop
                    : undefined,
                external_ems_server_id,
                external_ems_grid_code,
                // external_ems_meter1,
                external_ems_meter2,
                battery_user_soc_min,
                battery_user_soc_max,
                log_level_process_mgr,
                log_level_system_log,
                log_level_fota,
                log_level_power_control,
                log_level_algorithm_mgr,
                log_level_ess_mgr,
                log_level_dc_source_mgr,
                log_level_cloud_mgr,
                log_level_meter_mgr,
                log_level_gateway_mgr,
                log_level_data_accumulator,
                log_level_db_mgr,
                log_level_web_engine,
                log_level_gqa,
                log_level_mlpe_mgr,
                log_level_sys_mgr,
                log_level_energy_link,
                log_level_diag_monitor,
                log_level_csip_mgr,
                log_level_energy_optimizer,
                debug_mode_mqtt,
                debug_mode_modbus,
                auto_fw_update_flag,
            };
            const cleanedParam = pickBy(param, (v) => v !== undefined);
            return cleanedParam;
        }
        function onSave() {
            const confirmItems = getParam();
            const params = getEngineerSettingPvModuleGen3SmartModuleGen2Config(t, selectorOptionsState)
                .map((config) => {
                return {
                    ...config,
                    children: config.children
                        .map((item) => {
                        const itemValue = confirmItems[item.code];
                        const value = item.type === 'number' || item.type === 'text'
                            ? itemValue
                            : !isNull(itemValue)
                                ? item.options?.filter((option) => option.value == itemValue)[0]?.text
                                : undefined;
                        return {
                            ...item,
                            value,
                        };
                    })
                        .filter((item) => !isNull(item.value)),
                };
            })
                .filter((config) => config.children.length > 0);
            state.confirmPopup = { on: true, data: params };
        }
        function onConfirm() {
            state.confirmPopup.on = false;
            emit('save', getParam(), (isOk) => {
                if (isOk)
                    isEditable.value = false;
            });
        }
        async function onEdit() {
            if (!props.isConnection) {
                messageBox.alert(t('message.modify_when_disconnected')).open();
                return;
            }
            copyData = cloneDeep(state.data);
            const fcasSettings = await getFcasDefault();
            state.data = { ...state.data, ...fcasSettings };
            isEditable.value = true;
            emit('edit', copyData);
        }
        function onCancel(handleReset) {
            state.data = copyData;
            isEditable.value = false;
            if (handleReset)
                handleReset();
            emit('cancel');
        }
        function goToList() {
            emit('goToList');
        }
        function onChange(valueKey, targetValue) {
            if (isEditable.value) {
                if (!isNull(targetValue)) {
                    // 변경 항목 key set에 저장
                    state.changedValueSet.add(valueKey);
                }
                else {
                    // 변경 항목 key set에서 제거
                    state.changedValueSet.delete(valueKey);
                }
            }
            else {
                // cancel 버튼 클릭 시
                state.changedValueSet = copyChangedValueSet;
            }
        }
        onMounted(() => {
            if (props.isAcCoupled) {
                state.data.sg_ready_heat_pump_consumption_power = 0;
            }
        });
        watch(() => state.data.fcas_flag, async (flag) => {
            if (flag) {
                const fcasSettings = await getFcasDefault();
                state.data = { ...state.data, ...fcasSettings };
            }
        });
        watch(() => state.data.pcs_connection_mode, (mode) => {
            if (mode == PCS_CONNECTION_MODE.INTERNAL) {
                // internal -> CAN 통신으로 변경
                state.data.external_ems_connection = EXTERNAL_EMS_CONNECTION.CAN;
            }
            else if (mode == PCS_CONNECTION_MODE.EXTERNAL) {
                // external -> external ems model을 FOXESS H1으로 변경 & Modbus RTU 통신으로 변경
                state.data.external_ems_model = EXTERNAL_EMS_MODEL.FOXESS_H1;
                state.data.external_ems_connection = EXTERNAL_EMS_CONNECTION.MODBUS_RTU;
            }
        });
        watch(() => state.data.external_ems_model, (model) => {
            if (model == EXTERNAL_EMS_MODEL.FOXESS_H1 || model == EXTERNAL_EMS_MODEL.FOXESS_H3) {
                // model이 FOXESS면 Modbus RTU 통신으로 변경
                state.data.external_ems_connection = EXTERNAL_EMS_CONNECTION.MODBUS_RTU;
            }
        });
        await loadSelectorOptions();
        return {
            state,
            schema,
            computedVal,
            selectorOptionsState,
            onSave,
            onEdit,
            onCancel,
            goToList,
            onChange,
            onConfirm,
            isDisabledEMSModBus,
            isDisabledEMSConnection,
            PCS_CONNECTION_MODE,
            EXTERNAL_EMS_CONNECTION,
            isEditable,
            isSetFCAS,
            isHCORE,
            isQCORE,
            isACCoupled,
        };
    },
});
