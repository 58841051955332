import { USER_AGENT_TYPE } from '@hems/util/src/constant/constant';
import { WEB_MAPPER_HEIGHT, WEB_MAPPER_WIDTH } from '@hems/util/src/constant/mapper';
export function checkUserAgent() {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log('navigator.maxTouchPoints:', navigator.maxTouchPoints);
    const isAOS = userAgent.search(USER_AGENT_TYPE.ANDROID) > -1;
    const isIOS = userAgent.search(USER_AGENT_TYPE.IPHONE) > -1 ||
        userAgent.search(USER_AGENT_TYPE.IPAD) > -1 ||
        navigator.maxTouchPoints !== 0;
    if (isAOS) {
        console.log('userAgent === aos');
        return USER_AGENT_TYPE.AOS;
    }
    if (isIOS) {
        console.log('userAgent === ios');
        return USER_AGENT_TYPE.IOS;
    }
    console.log('userAgent === pc');
    return USER_AGENT_TYPE.PC;
}
export function isModuleType(object) {
    return object.qtype === 'module';
}
export function isArrayType(object) {
    return Array.isArray(object._objects) && object._objects.length > 0 && object.qtype === 'array';
}
export function isTextType(object) {
    return object.qtype === 'i-text';
}
export function isStringTextType(object) {
    return object.qtype === 'string-text';
}
export function isRectType(object) {
    return object.type === 'rect';
}
export function isArraySelectionType(object) {
    return object.qtype === 'array-selection';
}
export function isInverterType(object) {
    return object.qtype === 'inverter';
}
export function isPannelType(object) {
    return object.qtype === 'pannel';
}
export function isLogicalTextType(object) {
    return object.qtype === 'logical_text';
}
export function isTextBoxType(object) {
    return object.type === 'textbox';
}
export function isSelectObject(opt) {
    if (opt &&
        opt.target &&
        opt.target.type &&
        (opt.target.qtype == 'module' ||
            opt.target.qtype == 'array' ||
            opt.target.type == 'i-text' ||
            opt.target.type == 'textbox' ||
            opt.target.qtype == 'inverter')) {
        return true;
    }
    return false;
}
export function getSerialNumber(object) {
    if (object) {
        return object.get('qserial');
    }
    return '';
}
export function getInverterNumber(object) {
    if (object) {
        return object.get('inverterSerial');
    }
    return '';
}
export const getFingerDistance = (x1, x2, y1, y2) => {
    const diffX = x1 - x2;
    const diffY = y1 - y2;
    // 유클리드 거리 공식 : (x1 - x2)^2 + (y1 - y2)^2
    return Math.sqrt(diffX * diffX + diffY * diffY);
};
export function getCanvasSize() {
    const canvasWidth = checkUserAgent() === 'pc' ? WEB_MAPPER_WIDTH : window.innerWidth;
    const canvasHeight = checkUserAgent() === 'pc' ? WEB_MAPPER_HEIGHT : window.innerHeight;
    return [canvasWidth, canvasHeight];
}
