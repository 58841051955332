import { NUMBER_UNIT } from '@hems/util/src/constant';
/**
Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
export const ONE_MILE_TO_KILOMETER = 1.609;
export const WATT_HOUR_UNIT = {
    WATT_HOUR: 'Wh',
    KILLO_WATT_HOUR: 'kWh',
    MEGA_WATT_HOUR: 'MWh',
};
export const WATT_PEAK_UNIT = {
    WATT_PEAK: 'Wp',
    KILLO_WATT_PEAK: 'kWp',
    MEGA_WATT_PEAK: 'MWp',
};
export const NUMBER_UNIT_WATT_HOUR_UNIT_MAP = {
    [NUMBER_UNIT.MILLION]: WATT_HOUR_UNIT.MEGA_WATT_HOUR,
    [NUMBER_UNIT.TEN_THOUSAND]: WATT_HOUR_UNIT.KILLO_WATT_HOUR,
    [NUMBER_UNIT.THOUSAND]: WATT_HOUR_UNIT.KILLO_WATT_HOUR,
    [NUMBER_UNIT.HUNDRED]: WATT_HOUR_UNIT.WATT_HOUR,
};
export const NUMBER_UNIT_WATT_PEAK_UNIT_MAP = {
    [NUMBER_UNIT.MILLION]: WATT_PEAK_UNIT.MEGA_WATT_PEAK,
    [NUMBER_UNIT.TEN_THOUSAND]: WATT_PEAK_UNIT.KILLO_WATT_PEAK,
    [NUMBER_UNIT.THOUSAND]: WATT_PEAK_UNIT.KILLO_WATT_PEAK,
    [NUMBER_UNIT.HUNDRED]: WATT_PEAK_UNIT.WATT_PEAK,
};
export const CAPACITY_UINT_TYPE = {
    HOUR: 'hour',
    PEAK: 'peak',
};
export const UNIT = {
    WATT: 'W',
    VOLT: 'V',
    PERCENT: '%',
    TEMPERATURE: '°C',
    CURRENT: 'A',
    FREQUENCY: 'Hz',
    VAR: 'VAR',
};
export const INTL_NUMBER_FORMAT_UNIT = {
    FAHRENHEIT: 'fahrenheit',
    CELSIUS: 'celsius',
    PERCENT: 'percent',
    MILE_PER_HOUR: 'mile-per-hour',
    KILOMETER_PER_HOUR: 'kilometer-per-hour',
};
