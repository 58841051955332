import { computed, defineComponent, ref, watch } from 'vue';
import { useAppType } from '@hems/util';
class MessageBox {
    _type = 'alert';
    _title = '';
    #messages = '';
    _boxStyle;
    _titleStyle;
    _messageStyle;
    constructor() {
        this.init();
    }
    init(type = 'alert') {
        this._type = type;
        this._title = '';
        this.#messages = '';
        this._boxStyle = undefined;
        this._titleStyle = undefined;
        this._messageStyle = undefined;
        return this;
    }
    getInstance(resolve, reject) {
        return {
            on: true,
            type: this._type,
            title: this._title,
            messages: this.#messages,
            boxStyle: this._boxStyle,
            titleStyle: this._titleStyle,
            messageStyle: this._messageStyle,
            resolve,
            reject,
        };
    }
    alert(messages) {
        this.init('alert');
        this.#messages = messages;
        return this.chainSetMessage();
    }
    confirm(messages) {
        this.init('confirm');
        this.#messages = messages;
        return this.chainSetMessage();
    }
    remove(messages) {
        this.init('remove');
        this.#messages = messages;
        return this.chainSetMessage();
    }
    chainSetMessage() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title(title) {
                self._title = title;
                return this;
            },
            width(width) {
                self._boxStyle = { ...self._boxStyle, width };
                return this;
            },
            alignTitle(textAlign = 'left') {
                self._titleStyle = { ...self._titleStyle, textAlign };
                return this;
            },
            alignMessage(textAlign = 'center') {
                self._messageStyle = { ...self._messageStyle, textAlign };
                return this;
            },
            open() {
                return new Promise((resolve, reject) => {
                    _messageBox.value.on = false;
                    setTimeout(() => {
                        _messageBox.value = self.getInstance(resolve, reject);
                    }, 250);
                });
            },
        };
    }
}
const _messageBox = ref({});
const btnCancelEl = ref(null);
const close = () => {
    _messageBox.value.on = false;
};
export const useMessageBox = () => new MessageBox();
export default defineComponent({
    name: 'MessageBox',
    setup() {
        const { isPortalApp } = useAppType();
        const messageBox = computed(() => _messageBox.value);
        const messages = computed(() => {
            const { messages } = messageBox.value;
            return `<p>${Array.isArray(messages) ? messages.join('<br/>') : messages}</p>`;
        });
        const onOk = () => {
            const { resolve } = messageBox.value;
            if (resolve)
                resolve(true);
            close();
        };
        const onCancel = () => {
            const { resolve } = messageBox.value;
            if (resolve)
                resolve(false);
            close();
        };
        watch(() => _messageBox.value.on, (on) => {
            if (on) {
                setTimeout(() => {
                    btnCancelEl.value?.focus();
                }, 250);
            }
        });
        return {
            messageBox,
            messages,
            onOk,
            onCancel,
            btnCancelEl,
            isPortalApp,
        };
    },
});
