import { useI18n } from 'vue-i18n';
import { useGenType, useMessageBox } from '../..';
import { GEN_TYPE } from '../../constant';
import { STATUS_CODE } from '../../exception/exception';
export const useSettingMessageBox = () => {
    const { alertMessageBox } = useMessageBox();
    const { genType } = useGenType();
    const { t } = useI18n();
    /* control, setting 장비 에러 처리를 위한 팝업을 띄우는 함수 (서버는 성공을 응답하였으나 EMS에서 실패한 경우) */
    const settingErrorMessageBox = (errorCode, errorMessage, isSetting = true, failStringKey = 'message.save_fail') => {
        const errorStringKey = () => {
            if (genType.value === GEN_TYPE.GEN2) {
                return isSetting ? `code.cmdgen2configreqstatuscd.${errorCode}` : `code.cmdgen2controlreqstatuscd.${errorCode}`;
            }
            return isSetting ? `code.cmdgen3configreqstatuscd.${errorCode}` : `code.cmdgen3controlreqstatuscd.${errorCode}`;
        };
        const errorDescription = t(errorStringKey(), { defaultValue: errorMessage });
        const errorType = errorCode === STATUS_CODE.WARNING ? 'device.warning' : failStringKey;
        alertMessageBox([
            t(errorType),
            `${t('device.err_code')}: ${errorCode}`,
            `${t('device.description')}: ${errorDescription}`,
        ]);
    };
    /** Axios 에러 처리를 위한 map return 함수 */
    const getSettingErrorMap = (errorCode, errorMessage) => ({
        [STATUS_CODE.INTERNAL_SERVER_ERROR]: async () => {
            settingErrorMessageBox(errorCode, errorMessage);
        },
        [STATUS_CODE.BAD_REQUEST]: async () => {
            settingErrorMessageBox(errorCode, errorMessage);
        },
    });
    return {
        settingErrorMessageBox,
        getSettingErrorMap,
    };
};
