import { defineComponent, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import cloneDeep from 'lodash-es/cloneDeep';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, BasicInput, ToggleInput, Selector, SettingTable, SettingTableRow, RadioGroup, useMessageBox, ChangedMark, } from '@hems/component';
import { getGen2Config } from '@hems/container/src/forms/device/settings/_shared/advanced/configFunctionsGen2';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { DeviceServiceInstaller, CommonService } from '@hems/service';
import { Helper } from '@hems/util';
import { CSS_COLUMN } from '@hems/util/src/constant';
import { getEditableSettingConfig, getSchema, getRangeDescription, getIncludedSettingConfig, getSettingValue, } from './advancedSettingsUtils';
export default defineComponent({
    name: 'AdvancedSettingsGEN2',
    components: {
        Selector,
        Accordion,
        BasicInput,
        ToggleInput,
        Form,
        SettingTable,
        SettingTableRow,
        RadioGroup,
        ChangedMark,
        SettingConfirmPopup,
    },
    props: {
        productModelNm: {
            type: String,
            required: true,
        },
        roleNm: {
            type: String,
            required: true,
        },
        cntryCd: {
            type: String,
            required: true,
        },
        config: {
            type: Array,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        showEditableColumnsOnly: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        isConnection: Boolean,
        changedValueSet: {
            type: Object,
            default: () => new Set(),
        },
    },
    emits: ['goToList', 'save', 'cancel', 'edit'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        let copyData = cloneDeep(props.data);
        let copyChangedValueSet = cloneDeep(props.changedValueSet);
        const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
        const commonService = new CommonService(window.axiosInstance.axios);
        let gridCodeInfo;
        // 수정 가능한 컬럼 추출을 위한 Object
        const editableColumnsByGridCode = {};
        const state = reactive({
            isInit: false,
            expandAll: true,
            editable: props.editable,
            advancedSettingConfig: props.config,
            gridCodeOptions: [],
            gridCodeInfoList: [],
            editableCodes: [],
            excludedColumns: [],
            schema: yup.object().shape({}),
            data: cloneDeep(props.data),
            countryCode: props.cntryCd,
            genType: null,
            changedValueSet: cloneDeep(props.changedValueSet),
            confirmPopup: { on: false, data: [] },
        });
        watch(() => props.data, () => {
            copyData = cloneDeep(props.data);
            state.data = cloneDeep(props.data);
        });
        state.genType = await commonService.getBaseGenType(props.productModelNm);
        // Grid 코드 정보
        state.gridCodeInfoList = await deviceService
            .getGridCodeGen2({
            productModelNm: props.productModelNm,
            cntryCd: props.cntryCd === undefined ? '' : props.cntryCd,
        })
            .then((response) => {
            gridCodeInfo = response;
            return response.default_values;
        })
            .catch((e) => {
            console.error(e);
            return [];
        });
        state.gridCodeInfoList.forEach((item) => {
            editableColumnsByGridCode[item.grid_code] = item.column_names
                ?.split(',')
                .map((item) => item.trim().toLowerCase());
            if ([36, 361, 362, 363].includes(item.grid_code)) {
                state.excludedColumns = ['gc_10min_voltage_out'];
            }
        });
        // Selector Option 변환
        state.gridCodeOptions = state.gridCodeInfoList.map((item) => ({
            text: item.profile_name,
            value: item.grid_code,
        }));
        function init() {
            if (props.editable) {
                onEdit();
            }
            else {
                refreshForm();
            }
            state.isInit = true;
        }
        // eslint-disable-next-line complexity
        function refreshForm(data) {
            if (data)
                state.data = data;
            const editableColumnNames = state.data.base_profile_cd
                ? editableColumnsByGridCode[state.data.base_profile_cd]
                : [];
            if (props.showEditableColumnsOnly && editableColumnNames === undefined) {
                state.advancedSettingConfig = [];
                state.schema = yup.object().shape({});
                return;
            }
            // Grid Code 별 Config 가져오기
            let tempConfig = props.config;
            tempConfig = getGen2Config(props.config, state.data?.base_profile_cd);
            const config = ['dev'].includes(props.roleNm ?? '') ||
                (['installer', 'admin', 'service'].includes(props.roleNm ?? '') && !state.editable)
                ? getIncludedSettingConfig(tempConfig, state.excludedColumns)
                : ['installer', 'admin', 'service'].includes(props.roleNm ?? '') && state.editable
                    ? getEditableSettingConfig(tempConfig, editableColumnNames)
                    : [];
            const schema = getSchema(config);
            state.advancedSettingConfig = config;
            state.schema = schema;
        }
        function setDefaultByGridCode(gridCode) {
            const gridCodeInfo = state.gridCodeInfoList?.find((item) => item.grid_code === gridCode);
            if (!gridCodeInfo) {
                return;
            }
            const defaultValue = { ...state.data, ...getDefaultValueByGridCodeInfo(gridCodeInfo) };
            refreshForm(defaultValue);
        }
        function getDefaultValueByGridCodeInfo(gridCodeInfo) {
            const defaultValue = omit(gridCodeInfo, ['profile_name', 'grid_code', 'column_names']);
            return defaultValue;
        }
        function goToList() {
            emit('goToList');
        }
        function onChangeGridCode(value) {
            setDefaultByGridCode(value);
        }
        function onEdit() {
            if (!props.isConnection) {
                messageBox.alert(t('message.modify_when_disconnected')).open();
                return;
            }
            copyData = cloneDeep(state.data);
            copyChangedValueSet = cloneDeep(state.changedValueSet);
            state.editable = true;
            state.expandAll = true;
            refreshForm();
            emit('edit', copyData);
        }
        function onSave() {
            if (!state.data.base_profile_cd) {
                messageBox.alert('Grid Code is required').open();
                return;
            }
            const editableColumnNames = state.data.base_profile_cd
                ? editableColumnsByGridCode[state.data.base_profile_cd]
                : [];
            const filteredData = Object.fromEntries(Object.entries(state.data).filter(([key, value]) => !Helper.isNull(value) && editableColumnNames?.includes(key)));
            const confirmItems = Object.fromEntries(Object.entries(filteredData).filter(([key]) => !state.excludedColumns.includes(key)));
            const params = [...props.config]
                .map((config) => {
                if (config.role && !isEmpty(config.role)) {
                    const copyConfig = config;
                    if (!copyConfig.role?.includes(props.roleNm)) {
                        copyConfig.hide = true;
                    }
                    config = { ...copyConfig };
                }
                return {
                    ...config,
                    children: config.children
                        .map((item) => {
                        return {
                            ...item,
                            value: getSettingValue(item, confirmItems),
                        };
                    })
                        .filter((item) => !Helper.isNull(item.value)),
                };
            })
                .filter((config) => config.children.length > 0);
            state.confirmPopup = { on: true, data: params };
        }
        function onConfirm() {
            state.confirmPopup.on = false;
            emit('save', omit(state.data, state.excludedColumns), state.changedValueSet, (isOk) => {
                if (isOk)
                    state.editable = false;
            });
        }
        function onCancel() {
            state.editable = false;
            refreshForm(copyData);
            emit('cancel');
            state.changedValueSet = copyChangedValueSet;
        }
        function onChange(valueKey, targetValue) {
            if (state.editable) {
                if (!Helper.isNull(targetValue)) {
                    // 변경 항목 key set에 저장
                    state.changedValueSet.add(valueKey);
                }
                else {
                    // 변경 항목 key set에서 제거
                    state.changedValueSet.delete(valueKey);
                }
            }
        }
        init();
        return {
            state,
            onChangeGridCode,
            goToList,
            onEdit,
            onSave,
            onCancel,
            onChange,
            onConfirm,
            getRangeDescription,
            CSS_COLUMN,
        };
    },
});
