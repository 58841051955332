/* eslint-disable import/prefer-default-export */
import { inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMessageBox } from '@hems/component';
import { DeviceServiceUser } from '@hems/service';
import { AuthHelper, Helper, useRole } from '@hems/util';
import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant';
import { ROLE_NAME } from '@hems/util/src/constant/role';
export const useAuthentication = () => {
    const store = useStore();
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const { roleName } = useRole();
    const userName = computed(() => store.state.user.userId);
    const isLogin = computed(() => store.getters['user/isAuthenticated']);
    const facebookOAuth = inject(PROVIDE_INJECT_KEYS.FACEBOOK_OAUTH);
    const appleOAuth = inject(PROVIDE_INJECT_KEYS.APPLE_OAUTH);
    const googleOAuth = inject(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH);
    const deviceService = new DeviceServiceUser(window.axiosInstance.axios);
    const logoutSocialOAuth = async () => {
        const isGoogleLoggedIn = googleOAuth?.isLogin();
        const isFacebookLoggedIn = await facebookOAuth?.isLogin();
        if (isGoogleLoggedIn) {
            googleOAuth?.logout();
        }
        else if (isFacebookLoggedIn) {
            facebookOAuth?.logout();
        }
    };
    const logout = async (clearSocialToken) => {
        AuthHelper.removeAccessToken();
        store.dispatch('user/clear');
        if (clearSocialToken) {
            logoutSocialOAuth();
        }
        window.axiosInstance.clearAccessToken();
    };
    const getSiteId = async () => {
        try {
            const siteId = await deviceService.getSiteId();
            return siteId ?? null;
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };
    const setTokenInfo = async (accessToken) => {
        if (!accessToken)
            return;
        store.dispatch('user/setToken', accessToken);
        // 토큰 만료 여부 체크
        if (store.getters['user/isTokenExpired']) {
            messageBox.alert(t('message.token_expired')).open();
            store.commit('user/clear');
            return;
        }
        // 일반 사용자가 로그인 할 경우, site id 존재 여부를 파악후 없을 경우 로그인 하지 못하도록 함.
        if (roleName.value === ROLE_NAME.USER) {
            const siteId = await getSiteId();
            if (Helper.isNull(siteId)) {
                messageBox.alert(t('message.device_not_exist')).open();
                store.commit('user/clear');
                return;
            }
        }
    };
    const initializeAuthInfo = () => {
        const accessToken = AuthHelper.getAccessToken();
        setTokenInfo(accessToken || '');
    };
    return {
        initializeAuthInfo,
        isLogin,
        setTokenInfo,
        logout,
        userName,
        roleName,
        facebookOAuth,
        appleOAuth,
        googleOAuth,
    };
};
