import { createRouter, createWebHistory } from 'vue-router';
import { Helper } from '@hems/util';
import store from '@/store';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import NewHome from '@/views/NewHome.vue';
import PageWrapper from '@/views/PageWrapper.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: NewHome,
    },
    // FIXME 임시로 기존 페이지 남겨둠. 제거 필요
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (store.getters['user/isAuthenticated'] && !store.getters['user/isTokenExpired']) {
                next('/');
            }
            else {
                next();
            }
        },
    },
    {
        path: '/pages/terms-and-conditions',
        name: 'TermAndConditionPage',
        component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/TermAndConditionPage.vue'),
    },
    {
        path: '/pages/eu-terms-and-conditions',
        name: 'EUTermsAndConditionPage',
        component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/EUTermsAndConditionPage.vue'),
    },
    {
        path: '/pages/au-terms-and-conditions',
        name: 'AUTermsAndConditionPage',
        component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/AUTermsAndConditionPage.vue'),
    },
    {
        path: '/pages/us-terms-and-conditions',
        name: 'USTermsAndConditionPage',
        component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/USTermsAndConditionPage.vue'),
    },
    {
        path: '/pages/nz-terms-and-conditions',
        name: 'NZTermsAndConditionPage',
        component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/NZTermsAndConditionPage.vue'),
    },
    {
        path: '/.well-known',
        name: 'PageWrapper',
        component: PageWrapper,
        children: [
            {
                path: 'security.txt',
                name: 'SecurityText',
                component: () => import(/* webpackChunkName: "cyberSecurity" */ '@/views/cyberSecurity/SecurityText.vue'),
            },
        ],
    },
    {
        path: '/.well-known',
        name: 'PageWrapper',
        component: PageWrapper,
        children: [
            {
                path: 'security.txt',
                name: 'SecurityText',
                component: () => import(/* webpackChunkName: "cyberSecurity" */ '@/views/cyberSecurity/SecurityText.vue'),
            },
        ],
    },
    {
        path: '/mobile/gen2/terms',
        name: 'TermsMobileGen2',
        component: () => import(/* webpackChunkName: "termsMobile" */ '@/views/terms/TermsMobileGen2.vue'),
    },
    {
        path: '/mobile/gen2/terms/nz',
        name: 'NZTermsMobileGen2',
        component: () => import(/* webpackChunkName: "termsMobile" */ '@/views/terms/NZTermsMobileGen2.vue'),
    },
    {
        path: '/redirect/Unauthorized',
        name: 'Unauthorized',
        component: () => import(/* webpackChunkName: "error" */ '@hems/component/src/redirectPages/UnauthorizedPage.vue'),
    },
    {
        path: '/redirect/PageNotFound',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "error" */ '@hems/component/src/redirectPages/NotFoundPage.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/redirect/PageNotFound',
    },
];
const router = createRouter({
    history: createWebHistory(Helper.getAppEnv() === 'local' ? process.env.BASE_URL : undefined),
    routes,
});
router.onError((error) => {
    if (/ChunkLoadError:.*failed./i.test(error.message) || error.name === 'ChunkLoadError') {
        window.location.reload();
    }
});
export default router;
