import { defineComponent, computed, onBeforeUnmount, onMounted, watch, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import EnergyFlowAC from '@hems/container/src/energyflow/flow/EnergyFlowAC.vue';
import EnergyFlowACHomeLayer from '@hems/container/src/energyflow/flow/EnergyFlowACHomeLayer.vue';
import EnergyFlowACPipeLayer from '@hems/container/src/energyflow/flow/EnergyFlowACPipeLayer.vue';
import EnergyFlowACProductLayer from '@hems/container/src/energyflow/flow/EnergyFlowACProductLayer.vue';
import { defaultEnergyFlow, getEnergyFlowCases, getEnergyFlowFormattedData, } from '@hems/container/src/energyflow/flow/EnergyFlowFunctions';
import GridInfo from '@hems/container/src/energyflow/flow/GridInfo.vue';
import NetworkStatus from '@hems/container/src/energyflow/flow/NetworkStatus.vue';
import OperationModeInfo from '@hems/container/src/energyflow/flow/OperationModeInfo.vue';
import ProductionInfo from '@hems/container/src/energyflow/flow/ProductionInfo.vue';
import { useGenerator, useLocale } from '@hems/util';
import { PROVIDE_INJECT_KEYS, NETWORK_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'EnergyFlowACContainer',
    components: {
        EnergyFlowACHomeLayer,
        EnergyFlowACPipeLayer,
        EnergyFlowAC,
        EnergyFlowACProductLayer,
        NetworkStatus,
        ProductionInfo,
        OperationModeInfo,
        GridInfo,
    },
    props: {
        realtimeData: {
            type: Object,
            required: true,
        },
        isRealtimeConnected: {
            type: Boolean,
            required: false,
            default: false,
        },
        disconnect: {
            type: Boolean,
            required: false,
            default: true,
        },
        isConnectThirdParty: {
            type: Boolean,
            default: false,
        },
        siteConfigurationInfo: {
            type: Object,
            default: null,
        },
        isConsumptionCt: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const popup = inject(PROVIDE_INJECT_KEYS.CONTROL_POPUP);
        const { intlFormatLocale } = useLocale();
        const energyFlow = ref(defaultEnergyFlow);
        const realtimeMonitoringData = computed(() => props.realtimeData);
        const formattedData = ref(getEnergyFlowFormattedData(props.realtimeData, t, intlFormatLocale.value));
        const { isGenerator } = useGenerator();
        const isOffGrid = computed(() => !(realtimeMonitoringData.value.grid_status ?? true));
        const isGeneratorPower = computed(() => (realtimeMonitoringData.value.generator_pw ?? 0) > 0);
        const showGenerator = computed(() => isOffGrid.value && isGenerator && isGeneratorPower.value);
        const showFlow = (data) => {
            energyFlow.value = getEnergyFlowCases(data, showGenerator.value);
        };
        const clearEnergyFlow = () => {
            energyFlow.value = defaultEnergyFlow;
        };
        watch(() => realtimeMonitoringData.value, () => {
            showFlow(realtimeMonitoringData.value);
        });
        watch(() => props.disconnect, () => {
            if (props.disconnect) {
                clearEnergyFlow();
            }
        });
        watch(() => [intlFormatLocale.value, realtimeMonitoringData.value], () => {
            formattedData.value = getEnergyFlowFormattedData(realtimeMonitoringData.value, t, intlFormatLocale.value);
        });
        watch(() => props.isRealtimeConnected, clearEnergyFlow);
        onMounted(() => {
            showFlow(realtimeMonitoringData.value);
        });
        onBeforeUnmount(() => {
            clearEnergyFlow();
        });
        return {
            popup,
            energyFlow,
            realtimeMonitoringData,
            formattedData,
            NETWORK_TYPE,
            showGenerator,
        };
    },
});
