import { defineComponent, onMounted, inject } from 'vue';
import { AuthHelper } from '@hems/util';
import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant';
import { Image } from '@/components';
export default defineComponent({
    name: 'NewGoogleLoginButton',
    components: {
        Image,
    },
    emits: ['googleLogin'],
    setup(props, { emit }) {
        const googleOAuth = inject(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH);
        const handleCredentialResponse = (response) => {
            const token = response.credential;
            const parsedObject = AuthHelper.parseJwt(token);
            googleOAuth?.setAccessToken(token);
            googleOAuth?.setEmail(parsedObject?.email ?? '');
            googleOAuth?.setName(parsedObject?.name ?? '');
            emit('googleLogin');
        };
        const init = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                callback: handleCredentialResponse,
            });
        };
        const renderButton = () => {
            window.google.accounts.id.renderButton(document.getElementById('googleLoginBtn'), {
                theme: 'outline',
                size: 'large',
                type: 'icon',
                shape: 'square',
                width: '100',
            });
        };
        onMounted(() => {
            init();
            renderButton();
        });
    },
});
