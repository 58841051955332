import { computed, defineComponent, reactive, watch, inject, defineAsyncComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useIdle } from '@vueuse/core';
import axios from 'axios';
import isEmpty from 'lodash-es/isEmpty';
import { NOTICE_TARGET_WEB_VALUES } from '@hems/admin/src/config/noticeConfig';
import { useMessageBox, LanguageSelector } from '@hems/component';
import { AuthService, CommonService, DeviceServiceUser } from '@hems/service';
import { AuthHelper, Helper, useLocale } from '@hems/util';
import { PROVIDE_INJECT_KEYS, localStorageKey, DEFAULT_LANGUAGE, ONE_HOUR_VALUE_IN_MILLISECONDS, } from '@hems/util/src/constant';
import { getCurrentTimestamp } from '@hems/util/src/helper/dateHelper';
import { BackgroundImage, Header, LabelButton, CIImage, UserInfoButton, Footer, NavRoot } from '@/components';
import { APP_STORE_TYPE, JOIN_TYPE, AUTH_CREDENTIAL_INFO } from '@/config/ConstantPortal';
const API_BASE_URL = process.env.VUE_APP_API_URL;
export default defineComponent({
    name: 'Home',
    components: {
        BackgroundImage,
        Header,
        LabelButton,
        CIImage,
        UserInfoButton,
        LanguageSelector,
        Footer,
        NavRoot,
        LoginPopupContainer: defineAsyncComponent(() => import('@/containers/popup/LoginPopupContainer.vue')),
        FindIdPwPopupContainer: defineAsyncComponent(() => import('@/containers/popup/FindIdPwPopupContainer.vue')),
        JoinPopupContainer: defineAsyncComponent(() => import('@/containers/popup/JoinPopupContainer.vue')),
        JoinTypeSelectionPopup: defineAsyncComponent(() => import('@/components/popup/join/JoinTypeSelectionPopup.vue')),
        AccountInfoPopup: defineAsyncComponent(() => import('@/components/popup/accountinfo/AccountInfoPopup.vue')),
        WithdrawalPopup: defineAsyncComponent(() => import('@hems/component/src/popup/WithdrawalPopup.vue')),
        ReAgreeTermsPopup: defineAsyncComponent(() => import('@/components/popup/ReAgreeTermsPopup.vue')),
        PrivacyPopup: defineAsyncComponent(() => import('@/components/popup/PrivacyPopup.vue')),
        MobileAppLinkPopup: defineAsyncComponent(() => import('@/components/popup/MobileAppLinkPopup.vue')),
        NoticePopup: defineAsyncComponent(() => import('@hems/component/src/popup/NoticePopup.vue')),
        IdleTimePopup: defineAsyncComponent(() => import('@hems/component/src/popup/IdleTimePopup.vue')),
        CyberSecurityPopup: defineAsyncComponent(() => import('@/components/popup/cyberSecurity/CyberSecurityPopup.vue')),
    },
    props: {
        login: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { locale: i18nLocale, t } = useI18n();
        const { locale } = useLocale();
        const messageBox = useMessageBox();
        const store = useStore();
        const authService = new AuthService(window.axiosInstance.axios);
        const deviceService = new DeviceServiceUser(window.axiosInstance.axios);
        const facebookOAuth = inject(PROVIDE_INJECT_KEYS.FACEBOOK_OAUTH);
        const googleOAuth = inject(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH);
        const languageCode = ref(DEFAULT_LANGUAGE);
        const userName = ref(null);
        const roleName = ref(null);
        const state = reactive({
            popup: {
                login: { on: props.login },
                findIdPw: { on: false, idpw: AUTH_CREDENTIAL_INFO.ID },
                joinType: { on: false },
                join: {
                    on: false,
                    joinType: JOIN_TYPE.GENERAL,
                    locale,
                },
                accountInfo: { on: false },
                withdrawal: { on: false },
                reagree: { on: false },
                privacy: { on: false },
                appDownload: { on: false, storeType: APP_STORE_TYPE.GOOGLE },
                notice: { on: false },
                idle: { on: false },
                cyberSecurity: { on: false },
            },
            noticeInfo: { title: '', contents: '', notice_target: '', notice_start_dt: '', notice_end_dt: '' },
        });
        // TODO: Popup 구조 변경 및 타입 지정 제거
        const isPauseBg = computed(() => {
            let isPause = false;
            for (const p in state.popup) {
                const popupName = p;
                if (state.popup[popupName].on) {
                    isPause = true;
                    break;
                }
            }
            return isPause;
        });
        const onChangeLanguage = () => {
            i18nLocale.value = languageCode.value;
            store.dispatch('appCtx/setLangCd', languageCode.value);
        };
        // TODO: Popup 구조 변경 및 타입 지정 제거
        function openPopup(popupName, params) {
            state.popup[popupName] = { ...state.popup[popupName], on: true, ...params };
        }
        function closePopup(popupName) {
            state.popup[popupName] = { ...state.popup[popupName], on: false };
        }
        async function onLogout(clearSocialToken) {
            if (state.popup.idle.on) {
                closePopup('idle');
            }
            AuthHelper.removeAccessToken();
            store.dispatch('user/clear');
            userName.value = null;
            roleName.value = null;
            if (clearSocialToken) {
                logoutSocialOAuth();
            }
            window.axiosInstance.clearAccessToken();
        }
        const logoutSocialOAuth = async () => {
            const isGoogleLoggedIn = googleOAuth?.isLogin();
            const isFacebookLoggedIn = await facebookOAuth?.isLogin();
            if (isGoogleLoggedIn) {
                googleOAuth?.logout();
            }
            else if (isFacebookLoggedIn) {
                facebookOAuth?.logout();
            }
        };
        async function setTokenInfo(accessToken) {
            // 초기화
            onLogout();
            if (!accessToken)
                return;
            // 토큰 파싱
            const jwtObject = AuthHelper.parseJwt(accessToken);
            if (!jwtObject)
                return;
            const username = jwtObject.preferred_username;
            const roleCd = jwtObject.auth_type_cd;
            const roleNm = AuthHelper.getRoleNm(jwtObject.auth_type_cd);
            // 토큰 만료 여부 체크
            if (AuthHelper.isExpired(jwtObject)) {
                messageBox.alert(t('message.token_expired')).open();
                return;
            }
            // 설정
            window.axiosInstance.setAccessToken(accessToken);
            // 일반 사용자가 로그인 할 경우, site id 존재 여부를 파악후 없을 경우 로그인 하지 못하도록 함.
            if (roleNm === 'user') {
                const siteId = await getSiteId();
                if (Helper.isNull(siteId)) {
                    messageBox.alert(t('message.device_not_exist')).open();
                    window.axiosInstance.clearAccessToken();
                    return;
                }
            }
            AuthHelper.saveAccessToken(accessToken);
            store.dispatch('user/setRole', { roleCd, roleNm });
            userName.value = username;
            store.dispatch('user/setUserId', username);
            roleName.value = roleNm;
        }
        async function getSiteId() {
            try {
                const siteId = await deviceService.getSiteId();
                return siteId ?? null;
            }
            catch (e) {
                console.error(e);
                return null;
            }
        }
        function onFindIdPw(type) {
            state.popup.findIdPw.idpw = type;
            closePopup('login');
            openPopup('findIdPw');
        }
        function onJoinType(social) {
            closePopup('login');
            state.popup.join.social = social;
            openPopup('joinType');
        }
        function onJoin(joinType, localeAU) {
            closePopup('joinType');
            state.popup.join.joinType = joinType;
            state.popup.join.locale = localeAU ?? locale;
            openPopup('join');
        }
        function onAccountInfo() {
            openPopup('accountInfo');
        }
        function onWithdrawal(popupName) {
            closePopup(popupName);
            openPopup('withdrawal');
        }
        const onReagree = async () => {
            if (!state.popup.reagree.accessToken || !state.popup.reagree.termVer)
                return;
            const accessToken = state.popup.reagree.accessToken;
            await authService.updateTermsVersion({ ver: state.popup.reagree.termVer }, accessToken);
            setTokenInfo(accessToken);
            closePopup('reagree');
        };
        const { idle } = useIdle(ONE_HOUR_VALUE_IN_MILLISECONDS);
        watch(idle, (idleValue) => {
            if (idleValue) {
                openPopup('idle');
            }
        });
        const onContinue = () => {
            closePopup('idle');
        };
        async function getNotice() {
            // 1. API 호출로 공지 여부(utc 시간으로 비교) 및 내용 가져오기
            // 2. localStorage에서 공지 Expired Time 비교하여 notice 표시 여부 확인
            try {
                /**
                 *  공지 API 호출
                 * GET /user/notice
                 * @params lang ('en', 'de', 'fr', 'ko')
                 * @response { title: string, contents: string, notice_target: string, notice_start_dt: string, notice_end_dt: string }
                 */
                // 우선 공지 언어는 영어만 가능하도록 처리 추후 변경 필요 시 재수정
                const axiosInstance = axios.create({
                    baseURL: API_BASE_URL,
                });
                const commonService = new CommonService(axiosInstance);
                const notice = await commonService.getNotice('en');
                // 공지가 없는 경우 localStorage에 저장한 공지 Expired Time 삭제 / 공지 띄우지 않음
                if (isEmpty(notice) && !Helper.isNull(localStorage.getItem(localStorageKey.notice))) {
                    localStorage.setItem(localStorageKey.notice, '');
                }
                const isNoticeTarget = NOTICE_TARGET_WEB_VALUES.includes(notice?.notice_target); // 공지 target 여부
                if (isNoticeTarget) {
                    state.noticeInfo = notice;
                    const expiredTime = localStorage.getItem(localStorageKey.notice); // 하루동안 다시 보지 않기의 만료 시간
                    // 현재 시간이 Expired Time 이후 (사용자가 Close 버튼을 클릭하고 24시간 후) 라면 공지를 띄움
                    const showNotice = !Helper.isNull(expiredTime) ? getCurrentTimestamp() > Number(expiredTime) : true;
                    if (showNotice)
                        state.popup.notice = { on: true };
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        const token = AuthHelper.getAccessToken();
        if (token)
            setTokenInfo(token);
        getNotice();
        return {
            state,
            isPauseBg,
            openPopup,
            setTokenInfo,
            onLogout,
            onFindIdPw,
            onJoinType,
            onJoin,
            onAccountInfo,
            onWithdrawal,
            onReagree,
            onContinue,
            userName,
            roleName,
            locale,
            languageCode,
            onChangeLanguage,
            JOIN_TYPE,
        };
    },
});
