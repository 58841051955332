import { defineComponent, onMounted, ref, computed } from 'vue';
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { promiseTimeout } from '@vueuse/core';
import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import { ButtonNew, List, withLoading, Checkbox } from '@hems/component';
import EmptyListArea from '@hems/container/src/energyManagement/EmptyListArea.vue';
import EnergyManagementNavigation from '@hems/container/src/energyManagement/EnergyManagementNavigation.vue';
import OperationAddEditModal from '@hems/container/src/energyManagement/modal/OperationAddEditModal.vue';
import PresetNameSettingModal from '@hems/container/src/energyManagement/modal/PresetNameSettingModal.vue';
import ScheduleAddEditModal from '@hems/container/src/energyManagement/modal/ScheduleAddEditModal.vue';
import { EnergyManagementService } from '@hems/service';
import { useDangerConfirmationModal, useEnergyManagement, useToast } from '@hems/util';
import { BUTTON_NEW_TYPE, LIST_ITEM_MODE, LIST_ITEM_SIZE, TWO_SECONDS_VALUE_IN_MILLISECONDS, } from '@hems/util/src/constant';
import { ENERGY_MANAGEMENT_SETTING_TYPE, MAX_COUNT_OPERATION_PER_WEEK, OPERATION_DAY_OF_WEEK_MULTILINGUAL_CODE, SCHEDULE_EVENT_TYPE, TOU_OPERATION_MULTILINGUAL_CODE, } from '@hems/util/src/constant/energyManagement';
import { isEverydayList, isWeekdayList, isWeekendList } from '@hems/util/src/helper/dateHelper';
import { createScheduleEventByOperation, isFullySetOperations, } from '@hems/util/src/helper/energyManagement/energyManagementHelper';
import { isEmptyArray } from '@hems/util/src/helper/helper';
import { ENERGY_MANAGEMENT_ACTION, ENERGY_MANAGEMENT_MUTATION } from '@hems/util/src/stores/modules/energyManagement';
export default defineComponent({
    name: 'OperationSetting',
    components: {
        EnergyManagementNavigation,
        EmptyListArea,
        ButtonNew,
        List,
        Checkbox,
    },
    emits: ['moveToPresetSetting', 'moveToScheduleSetting'],
    setup(_props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const { openToastMessage } = useToast();
        const { siteId, currentEnergyManagementSettingType, selectedOperationInfo, weeklySchedulerEventList, selectedScheduleInfo, scheduleInfoList, totalOperationCount, updateWeeklySchedulerEventList, getPresetOperationsById, } = useEnergyManagement();
        const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
        const isOperationModifiable = siteId.value && !isNil(selectedOperationInfo.value);
        const navigationTitle = ref(selectedOperationInfo.value?.name ?? '');
        const operationList = ref(selectedOperationInfo.value?.operations ?? []);
        const isOperationDeleteMode = ref(false);
        const isCheckedAll = ref(false);
        const isInputEdited = ref(false);
        const isPresetSetting = computed(() => currentEnergyManagementSettingType.value === ENERGY_MANAGEMENT_SETTING_TYPE.PRESET);
        const canAddOperation = computed(() => totalOperationCount.value < MAX_COUNT_OPERATION_PER_WEEK &&
            !isFullySetOperations(weeklySchedulerEventList.value));
        const getDayOfWeekString = (daysOfWeekList) => {
            if (isEverydayList(daysOfWeekList)) {
                return t('common.everyday');
            }
            if (isWeekdayList(daysOfWeekList)) {
                return t('common.weekday');
            }
            if (isWeekendList(daysOfWeekList)) {
                return t('common.weekend');
            }
            return daysOfWeekList.map((daysOfWeek) => t(OPERATION_DAY_OF_WEEK_MULTILINGUAL_CODE[daysOfWeek])).join(', ');
        };
        const convertOperationToListItem = (list) => list.map((operation) => ({
            id: operation.operationId,
            onStatus: false,
            activeStatus: false,
            mode: LIST_ITEM_MODE.DEFAULT,
            size: LIST_ITEM_SIZE.LARGE,
            mainContents: `${operation.startTime} ~ ${operation.endTime}`,
            description: [
                getDayOfWeekString(operation.daysOfWeek),
                t(TOU_OPERATION_MULTILINGUAL_CODE[operation.operation]),
            ],
            checkStatus: false,
            badgeStatus: null,
            iconImageInfo: null,
        }));
        const operationItemList = ref(convertOperationToListItem(operationList.value));
        const isDeletable = computed(() => operationItemList.value.some((item) => item.checkStatus));
        const openEditOperationModal = (operationId) => {
            const operation = operationList.value.find((operation) => operation.operationId === operationId) ?? null;
            if (operation) {
                operationAddEditModal.patchOptions({
                    attrs: { currentOperation: operation, currentOperationList: selectedOperationInfo.value?.operations },
                });
                operationAddEditModal.open();
            }
        };
        const activeAndInactiveOperation = async (operation) => {
            operationItemList.value = convertOperationToListItem(operationList.value).map((item) => {
                if (item.id === operation.operationId) {
                    return {
                        ...item,
                        activeStatus: true,
                    };
                }
                return item;
            });
            updateWeeklySchedulerEventList(weeklySchedulerEventList.value.map((scheduleEvent) => {
                if (scheduleEvent.eventType === SCHEDULE_EVENT_TYPE.TEMPORARY) {
                    return { ...scheduleEvent, eventType: SCHEDULE_EVENT_TYPE.HIGHLIGHT };
                }
                return scheduleEvent;
            }));
            await promiseTimeout(TWO_SECONDS_VALUE_IN_MILLISECONDS);
            operationItemList.value = operationItemList.value.map((item) => ({ ...item, activeStatus: false }));
            updateWeeklySchedulerEventList(weeklySchedulerEventList.value.map((scheduleEvent) => ({
                ...scheduleEvent,
                eventType: SCHEDULE_EVENT_TYPE.DEFAULT,
            })));
        };
        const updateSelectedOperationInfo = () => {
            store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_OPERATION_INFO, {
                ...selectedOperationInfo.value,
                operations: operationList.value,
            });
        };
        const onAddOperation = withLoading(async (operation) => {
            operationAddEditModal.close();
            if (!isOperationModifiable) {
                return;
            }
            try {
                if (isPresetSetting.value) {
                    await energyManagementService.postPresetOperation(siteId.value, selectedOperationInfo.value.id, operation);
                    await store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value);
                    operationList.value = getPresetOperationsById(selectedOperationInfo.value.id);
                }
                else {
                    store.commit(ENERGY_MANAGEMENT_MUTATION.ADD_SCHEDULE_OPERATION, {
                        scheduleName: selectedOperationInfo.value.id,
                        operation,
                    });
                    operationList.value = [...operationList.value, operation];
                }
                operationItemList.value = convertOperationToListItem(operationList.value);
                activeAndInactiveOperation(operation);
                updateSelectedOperationInfo();
                openToastMessage(t('common.operation_added'));
            }
            catch (e) {
                console.error(e);
                if (isPresetSetting.value) {
                    openToastMessage(t('message.fail_add_operation'), true);
                }
            }
        });
        const onEditOperation = withLoading(async (operation) => {
            operationAddEditModal.close();
            if (!siteId.value || isNil(selectedOperationInfo.value)) {
                return;
            }
            try {
                if (isPresetSetting.value) {
                    await energyManagementService.putPresetOperation(siteId.value, selectedOperationInfo.value.id, operation.operationId, omit(operation, 'operationId'));
                    await store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value);
                    operationList.value = getPresetOperationsById(selectedOperationInfo.value.id);
                }
                else {
                    store.commit(ENERGY_MANAGEMENT_MUTATION.EDIT_SCHEDULE_OPERATION, {
                        scheduleName: selectedOperationInfo.value.id,
                        operation,
                    });
                    operationList.value = operationList.value.map((item) => {
                        if (item.operationId === operation.operationId) {
                            return operation;
                        }
                        return item;
                    });
                }
                operationItemList.value = convertOperationToListItem(operationList.value);
                activeAndInactiveOperation(operation);
                updateSelectedOperationInfo();
                openToastMessage(t('message.successfully_saved'));
            }
            catch (e) {
                console.error(e);
                if (isPresetSetting.value) {
                    openToastMessage(t('message.fail_change_operation'), true);
                }
            }
        });
        const operationAddEditModal = useModal({
            component: OperationAddEditModal,
            attrs: {
                currentOperation: null,
                currentOperationList: selectedOperationInfo.value?.operations,
                onAdd: onAddOperation,
                onEdit: onEditOperation,
                onDetail: (scheduleEventList) => {
                    updateWeeklySchedulerEventList(scheduleEventList);
                },
                onInputEdited: (isEdited) => {
                    isInputEdited.value = isEdited;
                },
            },
        });
        const openAddOperationModal = () => {
            operationAddEditModal.patchOptions({
                attrs: { currentOperation: null, currentOperationList: selectedOperationInfo.value?.operations },
            });
            operationAddEditModal.open();
        };
        const leaveModal = useDangerConfirmationModal({
            title: t('message.leave_without_saving_modal'),
            content: t('message.save_before_leave'),
            confirmButtonText: t('common.leave'),
            confirmCallback: () => {
                moveToPrevPageBySettingType();
            },
        });
        const moveToPrevPageBySettingType = () => {
            if (currentEnergyManagementSettingType.value === ENERGY_MANAGEMENT_SETTING_TYPE.PRESET) {
                emit('moveToPresetSetting');
            }
            else {
                emit('moveToScheduleSetting');
            }
        };
        const onMoveToPrevPage = () => {
            if (isInputEdited.value && operationAddEditModal.options.modelValue) {
                leaveModal.open();
            }
            else {
                if (isOperationDeleteMode.value) {
                    isOperationDeleteMode.value = false;
                    operationItemList.value = operationItemList.value.map((item) => ({
                        ...item,
                        mode: LIST_ITEM_MODE.DEFAULT,
                    }));
                    return;
                }
                moveToPrevPageBySettingType();
            }
        };
        const deletePreset = withLoading(async () => {
            if (!isOperationModifiable) {
                return;
            }
            try {
                await energyManagementService.deletePreset(siteId.value, selectedOperationInfo.value.id);
                store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value);
                onMoveToPrevPage();
                openToastMessage(t('message.delete_complete'));
            }
            catch (e) {
                console.error(e);
                openToastMessage(t('message.fail_delete_preset'), true);
            }
        });
        const deletePresetModal = useDangerConfirmationModal({
            title: t('control.delete_preset_modal_title'),
            content: t('control.delete_preset_message'),
            confirmButtonText: t('common.delete'),
            confirmCallback: deletePreset,
        });
        const openDeletePresetModal = () => {
            deletePresetModal.open();
        };
        const updatePresetName = withLoading(async (presetName) => {
            try {
                if (!isOperationModifiable) {
                    return;
                }
                await energyManagementService.patchPresetName(siteId.value, selectedOperationInfo.value.id, {
                    name: presetName,
                });
                store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value);
                editPresetModal.close();
                navigationTitle.value = presetName;
                openToastMessage(t('message.successfully_saved'));
            }
            catch (e) {
                console.error(e);
                openToastMessage(t('message.fail_change_preset'), true);
            }
        });
        const editPresetModal = useModal({
            component: PresetNameSettingModal,
            attrs: {
                currentPresetName: navigationTitle.value,
                siteId: siteId.value,
                onDelete: openDeletePresetModal,
                onConfirm: updatePresetName,
            },
        });
        const deleteSchedule = (callback) => {
            store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SCHEDULE_INFO_LIST, scheduleInfoList.value.filter((schedule) => schedule.name !== selectedScheduleInfo.value?.name));
            updateWeeklySchedulerEventList([]);
            moveToPrevPageBySettingType();
            callback?.();
        };
        const editSchedule = (scheduleInfo, callback) => {
            const operations = selectedOperationInfo.value?.operations;
            const editScheduleInfo = { ...scheduleInfo, operations };
            store.commit(ENERGY_MANAGEMENT_MUTATION.UPDATE_SCHEDULE_INFO, {
                beforeName: navigationTitle.value,
                scheduleInfo: editScheduleInfo,
            });
            store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_SCHEDULE_INFO, scheduleInfo);
            store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_OPERATION_INFO, {
                ...selectedOperationInfo.value,
                id: scheduleInfo.name,
                name: scheduleInfo.name,
                operations: operationList.value,
            });
            navigationTitle.value = scheduleInfo.name;
            callback?.();
            openToastMessage(t('message.successfully_saved'));
        };
        const scheduleDeleteConfirmModal = useDangerConfirmationModal({
            title: t('control.delete_schedule_modal_title'),
            content: t('control.delete_schedule_message'),
            cancelButtonText: t('common.cancel'),
            confirmButtonText: t('common.delete'),
            cancelCallback: () => scheduleDeleteConfirmModal.close(),
            confirmCallback: () => {
                deleteSchedule(() => {
                    scheduleDeleteConfirmModal.close();
                    editScheduleModal.close();
                    updateWeeklySchedulerEventList([]);
                    moveToPrevPageBySettingType();
                });
            },
        });
        const editScheduleModal = useModal({
            component: ScheduleAddEditModal,
            attrs: {
                siteId: siteId.value,
                isEdit: true,
                scheduleInfo: selectedScheduleInfo.value,
                onDelete: () => {
                    scheduleDeleteConfirmModal.open();
                },
                onSave: ({ scheduleInfo }) => {
                    editSchedule(scheduleInfo, () => {
                        editScheduleModal.close();
                    });
                },
            },
        });
        const onEditScheduleOrPreset = () => {
            if (currentEnergyManagementSettingType.value === ENERGY_MANAGEMENT_SETTING_TYPE.PRESET) {
                editPresetModal.open();
            }
            else {
                editScheduleModal.open();
            }
        };
        const onChangeOperationDeleteMode = () => {
            isOperationDeleteMode.value = true;
            operationItemList.value = operationItemList.value.map((item) => ({
                ...item,
                mode: LIST_ITEM_MODE.CHECK,
            }));
        };
        const scheduleEventListByCheckStatus = computed(() => operationItemList.value
            .map((item) => {
            const operationInfo = operationList.value.find((operation) => operation.operationId === item.id);
            if (!operationInfo) {
                return [];
            }
            const scheduleEventList = createScheduleEventByOperation([operationInfo]);
            if (item.checkStatus) {
                return scheduleEventList.map((schedule) => ({ ...schedule, eventType: SCHEDULE_EVENT_TYPE.TEMPORARY }));
            }
            return scheduleEventList;
        })
            .flat());
        const onCheckAllOperation = (isChecked) => {
            isCheckedAll.value = isChecked;
            operationItemList.value = operationItemList.value.map((item) => ({
                ...item,
                checkStatus: isChecked,
            }));
            updateWeeklySchedulerEventList(scheduleEventListByCheckStatus.value);
        };
        const onCheckOperation = (operationId, isChecked) => {
            operationItemList.value = operationItemList.value.map((item) => ({
                ...item,
                checkStatus: item.id === operationId ? isChecked : item.checkStatus,
            }));
            isCheckedAll.value = operationItemList.value.every((item) => item.checkStatus);
            updateWeeklySchedulerEventList(scheduleEventListByCheckStatus.value);
        };
        const onDeleteOperation = withLoading(async () => {
            confirmDeleteModal.close();
            if (!isOperationModifiable) {
                return;
            }
            const idList = { ids: operationItemList.value.filter((item) => item.checkStatus).map((item) => item.id) };
            try {
                if (isPresetSetting.value) {
                    await energyManagementService.deletePresetOperationList(siteId.value, selectedOperationInfo.value.id, idList);
                    await store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value);
                    operationList.value = getPresetOperationsById(selectedOperationInfo.value.id);
                }
                else {
                    store.commit(ENERGY_MANAGEMENT_MUTATION.DELETE_SCHEDULE_OPERATION, {
                        scheduleName: selectedOperationInfo.value.id,
                        operationIdList: idList.ids,
                    });
                    operationList.value = operationList.value.filter((item) => !idList.ids.includes(item.operationId));
                }
                operationItemList.value = convertOperationToListItem(operationList.value);
                isOperationDeleteMode.value = false;
                updateWeeklySchedulerEventList(createScheduleEventByOperation(operationList.value));
                updateSelectedOperationInfo();
                openToastMessage(t('message.delete_complete'));
            }
            catch (e) {
                console.error(e);
                openToastMessage(t('message.fail_delete_operation'), true);
            }
        });
        const confirmDeleteModal = useDangerConfirmationModal({
            title: `${t('common.delete_operation')}?`,
            content: t('message.delete_operation_warning'),
            confirmButtonText: t('common.delete'),
            confirmCallback: onDeleteOperation,
        });
        const openConfirmDeleteModal = () => {
            confirmDeleteModal.open();
        };
        const onChangeOperationActiveStatus = (operationId) => {
            operationItemList.value = operationItemList.value.map((operationItem) => ({
                ...operationItem,
                activeStatus: operationItem.id === operationId ? !operationItem.activeStatus : false,
            }));
            const targetOperation = operationItemList.value.find((operationItem) => operationItem.activeStatus)?.id;
            const updatedScheduleEventList = weeklySchedulerEventList.value.map((scheduleEvent) => ({
                ...scheduleEvent,
                eventType: targetOperation === scheduleEvent.id ? SCHEDULE_EVENT_TYPE.HIGHLIGHT : SCHEDULE_EVENT_TYPE.DEFAULT,
            }));
            updateWeeklySchedulerEventList(updatedScheduleEventList);
        };
        onMounted(() => {
            if (isEmptyArray(operationList.value)) {
                openAddOperationModal();
            }
            if (selectedOperationInfo.value) {
                updateWeeklySchedulerEventList(createScheduleEventByOperation(selectedOperationInfo.value.operations));
            }
        });
        return {
            navigationTitle,
            operationList,
            operationItemList,
            isOperationDeleteMode,
            isCheckedAll,
            BUTTON_NEW_TYPE,
            isDeletable,
            canAddOperation,
            isEmptyArray,
            onEditScheduleOrPreset,
            openAddOperationModal,
            openEditOperationModal,
            onChangeOperationDeleteMode,
            onCheckAllOperation,
            onCheckOperation,
            onDeleteOperation,
            openConfirmDeleteModal,
            onMoveToPrevPage,
            onChangeOperationActiveStatus,
        };
    },
});
