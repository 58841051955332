import { defineComponent, computed, onMounted, ref } from 'vue';
import isEmpty from 'lodash-es/isEmpty';
import EnergyIndependence from '@hems/container/src/energyflow/value/EnergyIndependence.vue';
import EnergyValue from '@hems/container/src/energyflow/value/EnergyValue.vue';
import EnergyValueAC from '@hems/container/src/energyflow/value/EnergyValueAC.vue';
import EnergyValueACNoConsumptionCT from '@hems/container/src/energyflow/value/EnergyValueACNoConsumptionCT.vue';
import TodayConsumption from '@hems/container/src/energyflow/value/TodayConsumption.vue';
import TodayProduction from '@hems/container/src/energyflow/value/TodayProduction.vue';
import TodayProductionACNoConsumptionCT from '@hems/container/src/energyflow/value/TodayProductionACNoConsumptionCT.vue';
import { HomeApplianceService } from '@hems/service';
import { Constant, DateHelper, useGenType } from '@hems/util';
import { APPLIANCE_TYPE, GRAPH_INTERVAL } from '@hems/util/src/constant';
export default defineComponent({
    name: 'DashboardEnergyValueContainer',
    components: {
        TodayProduction,
        TodayConsumption,
        EnergyIndependence,
        EnergyValue,
        EnergyValueAC,
        TodayProductionACNoConsumptionCT,
        EnergyValueACNoConsumptionCT,
    },
    props: {
        realtimeData: {
            type: Object,
            required: true,
        },
        reportData: {
            type: Object,
            required: true,
        },
        baseDeviceInfo: {
            type: Object,
            default: null,
        },
        siteConfigurationInfo: {
            type: Object,
            default: null,
        },
        disconnect: {
            type: Boolean,
            default: false,
        },
        hourDate: {
            type: Object,
            required: true,
        },
        isConnectThirdParty: {
            type: Boolean,
            default: false,
        },
        isConsumptionCt: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['dataLoaded'],
    setup(props, { emit }) {
        const homeApplianceService = new HomeApplianceService(window.axiosInstance.axios);
        const { isACSystem } = useGenType();
        const showChart = ref(false);
        const homeEnergyData = ref({ isHomeAppliance: false, value: 0.0 });
        const hourDate = computed(() => props.hourDate);
        const baseDevice = computed(() => props.baseDeviceInfo);
        const siteConfiguration = computed(() => props.siteConfigurationInfo ?? null);
        const energyIndependenceData = computed(() => ({
            today: calculateEnergyIndependence(props.reportData.today ?? null),
            yesterday: calculateEnergyIndependence(props.reportData.yesterday ?? null),
        }));
        const energyValueContainerClassName = computed(() => getEnergyValueContainerClassName());
        const getEnergyValueContainerClassName = () => {
            if (isACSystem && !siteConfiguration.value?.hasPV) {
                return 'box-ac-no-pv';
            }
            return 'box-lft';
        };
        const getParams = () => {
            const start = DateHelper.formatDateByInterval(hourDate.value.today, GRAPH_INTERVAL.HOURLY);
            const end = DateHelper.formatDateByInterval(DateHelper.getHourlyEndValue(hourDate.value.today), GRAPH_INTERVAL.HOURLY);
            const params = {
                from: start,
                to: end,
                device_id: baseDevice.value?.device_id ?? '',
                site_id: Number(baseDevice.value?.site_id),
                product_model_nm: baseDevice.value?.product_model_nm ?? '',
                timezone_id: baseDevice.value?.timezone_id ?? '',
            };
            return params;
        };
        const calculateEnergyIndependence = (data) => {
            if (!data || data.con_kwh.origin === 0) {
                return { percent: 0, value: 0 };
            }
            const energyIndependence = convertAbs((data.con_kwh.origin - data.demand_kwh.origin) / data.con_kwh.origin);
            return { percent: convertAbs(energyIndependence * 100), value: energyIndependence };
        };
        const convertAbs = (num) => {
            if (num === null || num === undefined || isNaN(num)) {
                return 0;
            }
            if (num < 0) {
                return 0;
            }
            return num;
        };
        const getApplianceCount = async () => {
            try {
                const applianceList = await homeApplianceService.getApplianceList(Number(props.baseDeviceInfo?.site_id));
                const filteredApplianceList = applianceList.filter((item) => item.profile_cd === Constant.APPLIANCE_TYPE.HEAT_PUMP ||
                    item.profile_cd === Constant.APPLIANCE_TYPE.AIR_CONDITIONER ||
                    item.profile_cd === Constant.APPLIANCE_TYPE.SMART_PLUG ||
                    item.profile_cd === Constant.APPLIANCE_TYPE.TV);
                return filteredApplianceList.length;
            }
            catch (e) {
                console.error(e);
                return 0;
            }
        };
        const getApplianceEnergyData = async () => {
            const dateParams = getParams();
            try {
                const applianceChartData = await homeApplianceService.getApplianceChart(Number(props.baseDeviceInfo?.site_id), {
                    interval: GRAPH_INTERVAL.HOURLY,
                    from: dateParams.from,
                    to: dateParams.to,
                    timezoneId: dateParams.timezone_id,
                });
                const heatPumpSum = isEmpty(applianceChartData[APPLIANCE_TYPE.HEAT_PUMP])
                    ? 0
                    : applianceChartData[APPLIANCE_TYPE.HEAT_PUMP]
                        .map((item) => item.gen ?? 0)
                        .reduce((prev, curr) => {
                        return prev && curr ? prev + curr : prev;
                    }, 0);
                const airConditionerSum = isEmpty(applianceChartData[APPLIANCE_TYPE.AIR_CONDITIONER])
                    ? 0
                    : applianceChartData[APPLIANCE_TYPE.AIR_CONDITIONER]
                        .map((item) => item.gen ?? 0)
                        .reduce((prev, curr) => {
                        return prev && curr ? prev + curr : prev;
                    }, 0);
                const smartPlugSum = isEmpty(applianceChartData[APPLIANCE_TYPE.SMART_PLUG])
                    ? 0
                    : applianceChartData[APPLIANCE_TYPE.SMART_PLUG]
                        .map((item) => item.gen ?? 0)
                        .reduce((prev, curr) => {
                        return prev && curr ? prev + curr : prev;
                    }, 0);
                const tvSum = isEmpty(applianceChartData[APPLIANCE_TYPE.TV])
                    ? 0
                    : applianceChartData[APPLIANCE_TYPE.TV]
                        .map((item) => item.gen ?? 0)
                        .reduce((prev, curr) => {
                        return prev && curr ? prev + curr : prev ?? 0;
                    }, 0);
                return heatPumpSum + airConditionerSum + smartPlugSum + tvSum;
            }
            catch (e) {
                console.error(e);
                return 0;
            }
        };
        const loadHomeEnergyData = async () => {
            const applianceEnergyData = await getApplianceEnergyData();
            homeEnergyData.value = { isHomeAppliance: true, value: applianceEnergyData };
        };
        onMounted(async () => {
            showChart.value = true;
            const applianceCount = await getApplianceCount();
            if (applianceCount > 0) {
                loadHomeEnergyData();
            }
            emit('dataLoaded');
        });
        return {
            showChart,
            isACSystem,
            siteConfiguration,
            energyIndependenceData,
            homeEnergyData,
            energyValueContainerClassName,
            baseDevice,
            loadHomeEnergyData,
        };
    },
});
