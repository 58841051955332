import { useI18n } from 'vue-i18n';
import { useAlertModal, useRole } from '@hems/util';
import { RoleWebMap } from '@/config/ConstantPortal';
// eslint-disable-next-line import/prefer-default-export
export const useDashboard = () => {
    const { t } = useI18n();
    const { roleName } = useRole();
    const noAccessAlertModal = useAlertModal({ content: t('message.no_results') });
    const goDashboardPage = () => {
        const dashboardLink = roleName.value ? RoleWebMap[roleName.value] : '';
        if (!dashboardLink) {
            noAccessAlertModal.open();
            return;
        }
        window.location.href = dashboardLink;
    };
    return { goDashboardPage };
};
