import { computed, defineComponent, nextTick, watch, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import range from 'lodash-es/range';
import { withLoading, ToastGrid, Panel, ListNavigator } from '@hems/component';
import { getColumns } from '@hems/container/src/dataGrid/eventDataGrid/gridConfig';
import EventDetailPopup from '@hems/container/src/guide/EventDetailPopup.vue';
import { ErrorCodeService, EventServiceInstaller } from '@hems/service';
import { useLanguage, useLocale, useRole, useAlertModal } from '@hems/util';
import { SORT_ORDER } from '@hems/util/src/constant';
import { ERROR_HISTORY_DOWNLOAD_LIST_SIZE, ERROR_HISTORY_GRID_TYPE } from '@hems/util/src/constant/eventHistory';
import { eventHistorySortColumnMap, getErrorData, getErrorHistoryParams, getEventData, getEventHistoryParams, } from '@hems/util/src/helper/errorHistoryHelper';
import { isNull, formatNumber, downloadCSV, addSortOrderMark, getPatchedModalContent, } from '@hems/util/src/helper/helper';
import { getRouteInfo, isErrorCodeDisplayDetails, isNumberType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'EventDataGrid',
    components: {
        ToastGrid,
        Panel,
        ListNavigator,
        EventDetailPopup,
    },
    props: {
        searchCondition: {
            type: Object,
            required: true,
        },
        eventGridType: {
            type: String,
            default: ERROR_HISTORY_GRID_TYPE.ALL,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const route = useRoute();
        const alertModal = useAlertModal({ content: t('message.no_results') });
        const eventService = new EventServiceInstaller(window.axiosInstance.axios);
        const errorCodeService = new ErrorCodeService(window.axiosInstance.axios);
        const { languageCode } = useLanguage();
        const { roleName, isAdminGroup } = useRole();
        const showGrid = ref(true);
        const { isUS } = useLocale();
        const errorDetailPopupInfo = ref({
            isOpen: false,
            errorCode: '',
            errorStart: '',
        });
        const condition = ref({
            ...props.searchCondition,
            pageNumber: getRouteInfo(route.query.pageNumber, isNumberType) ?? 1,
            listSize: getRouteInfo(route.query.listSize, isNumberType) ?? 10,
            sort: isUS ? addSortOrderMark('triggerDate', SORT_ORDER.DESCENDING) : `event_dt ${SORT_ORDER.DESCENDING}`,
            language: languageCode.value,
        });
        const toastGrid = ref({
            gridOptions: {
                columns: computed(() => getColumns(t, props.eventGridType, roleName.value, isUS)),
                data: [],
                sortState: {
                    columnName: 'triggerDate',
                    ascending: false,
                    multiple: false,
                },
                options: {
                    bodyHeight: 'fitToParent',
                },
            },
        });
        const totalErrorHistoryCount = ref(0);
        const eventList = computed(() => toastGrid.value.gridOptions.data);
        const loadData = withLoading(async () => {
            try {
                const params = getEventHistoryParams(condition.value);
                const eventList = await eventService.getEventList(params);
                const { data, totalCount } = getEventData(eventList, t);
                toastGrid.value.gridOptions.data = data;
                totalErrorHistoryCount.value = totalCount;
            }
            catch (error) {
                console.error(error);
                toastGrid.value.gridOptions.data = [];
                condition.value.pageNumber = 1;
            }
        });
        const loadErrorList = withLoading(async () => {
            try {
                const params = getErrorHistoryParams(condition.value, languageCode.value);
                const errorHistory = await errorCodeService.getErrorHistory(params);
                const { data, totalCount } = getErrorData(errorHistory.data, errorHistory.totalCount);
                toastGrid.value.gridOptions.data = data;
                totalErrorHistoryCount.value = totalCount;
            }
            catch (error) {
                console.error(error);
                toastGrid.value.gridOptions.data = [];
                condition.value.pageNumber = 1;
            }
        });
        const loadErrorData = () => {
            if (isUS) {
                loadErrorList();
            }
            else {
                loadData();
            }
        };
        const onDownload = withLoading(async () => {
            const totalPages = Math.ceil(totalErrorHistoryCount.value / ERROR_HISTORY_DOWNLOAD_LIST_SIZE);
            try {
                if (isUS) {
                    const params = getErrorHistoryParams(condition.value, languageCode.value);
                    const errorHistoryPromiseList = range(1, totalPages + 1).map((page) => () => errorCodeService.getErrorHistory({
                        ...params,
                        listSize: ERROR_HISTORY_DOWNLOAD_LIST_SIZE,
                        pageNumber: page,
                    }));
                    const errorHistoryList = await Promise.all(errorHistoryPromiseList.map((errorHistoryPromise) => errorHistoryPromise()));
                    const errorHistory = errorHistoryList.map((errorHistory) => errorHistory.data).flat();
                    const { data } = getErrorData(errorHistory, errorHistory.length);
                    downloadCSV(data, toastGrid.value.gridOptions.columns, 'ErrorHistory');
                }
                else {
                    const params = getEventHistoryParams(condition.value);
                    const eventHistoryPromiseList = range(1, totalPages + 1).map((page) => () => eventService.getEventList({ ...params, listSize: ERROR_HISTORY_DOWNLOAD_LIST_SIZE, pageNum: page }));
                    const eventHistoryList = await Promise.all(eventHistoryPromiseList.map((eventHistoryPromise) => eventHistoryPromise()));
                    const { data } = getEventData(eventHistoryList.flat(), t);
                    downloadCSV(data, toastGrid.value.gridOptions.columns, 'EventHistory');
                }
            }
            catch (e) {
                console.error(e);
                alertModal.patchOptions(getPatchedModalContent(t('message.error_download')));
                alertModal.open();
            }
        });
        const onChangeListSize = (listSize, pageNumber) => {
            condition.value.pageNumber = pageNumber;
            condition.value.listSize = listSize;
            loadErrorData();
        };
        const onChangePage = (pageNumber) => {
            condition.value.pageNumber = pageNumber;
            loadErrorData();
        };
        const onClose = () => {
            errorDetailPopupInfo.value.isOpen = false;
            errorDetailPopupInfo.value.errorCode = '';
        };
        const beforeSortEventHandler = (gridEventProps) => {
            gridEventProps.stop();
            const { ascending, multiple, columnName } = gridEventProps;
            toastGrid.value.gridOptions.sortState = {
                columnName,
                ascending,
                multiple,
            };
            const sortOrder = ascending ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;
            if (isUS) {
                condition.value.sort = addSortOrderMark(columnName, sortOrder);
            }
            else {
                condition.value.sort = `${eventHistorySortColumnMap[columnName]} ${sortOrder}`;
            }
            loadErrorData();
        };
        const clickEventHandler = (gridEventProps) => {
            const { rowKey, columnName, instance } = gridEventProps;
            if (isNull(rowKey))
                return;
            // @TODO: 신규 이벤트 상세 팝업 적용 시 사용
            // const errorCode = String(instance.getValue(rowKey, 'errorCode'));
            // errorDetailPopupInfo.value.errorStart = String(instance.getValue(rowKey, 'triggerDate'));
            // if (columnName === 'description' && errorCode) {
            //   errorDetailPopupInfo.value.errorCode = errorCode;
            //   errorDetailPopupInfo.value.isOpen = true;
            // }
            if (columnName === 'description') {
                const errorDetails = instance.getValue(rowKey, 'display');
                if (isErrorCodeDisplayDetails(errorDetails)) {
                    const { description, howToFix } = errorDetails;
                    if (!description)
                        return;
                    alertModal.patchOptions(getPatchedModalContent(description));
                    if (howToFix) {
                        alertModal.patchOptions(getPatchedModalContent(`${description}\n\nHow To Fix\n${howToFix.trim()}`));
                    }
                    alertModal.open();
                }
            }
        };
        const events = {
            click: clickEventHandler,
            beforeSort: beforeSortEventHandler,
        };
        const rerenderDataGrid = () => {
            showGrid.value = false;
            nextTick(() => (showGrid.value = true));
        };
        onMounted(() => {
            loadErrorData();
        });
        watch(() => props.searchCondition, () => {
            condition.value = { ...condition.value, ...props.searchCondition };
            if (condition.value.pageNumber === 1) {
                loadErrorData();
                return;
            }
            condition.value.pageNumber = 1;
        });
        watch(() => languageCode.value, () => {
            if (isUS) {
                loadErrorData();
            }
            else {
                rerenderDataGrid();
            }
        });
        return {
            showGrid,
            totalErrorHistoryCount,
            eventList,
            errorDetailPopupInfo,
            toastGrid,
            condition,
            roleName,
            events,
            isAdminGroup,
            formatNumber,
            onClose,
            onChangeListSize,
            onDownload,
            onChangePage,
        };
    },
});
