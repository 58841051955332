import { defineComponent } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
export default defineComponent({
    name: 'Flow',
    components: {
        Vue3Lottie,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
    },
});
