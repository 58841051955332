import { defineComponent, ref, computed, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { promiseTimeout } from '@vueuse/core';
import isNil from 'lodash-es/isNil';
import { InputTitle, IconButton, List, withLoading } from '@hems/component';
import EmptyListArea from '@hems/container/src/energyManagement/EmptyListArea.vue';
import EnergyManagementNavigation from '@hems/container/src/energyManagement/EnergyManagementNavigation.vue';
import PresetNameSettingModal from '@hems/container/src/energyManagement/modal/PresetNameSettingModal.vue';
import { EnergyManagementService } from '@hems/service';
import { useConfirmationModal, useEnergyManagement, useWarningModal } from '@hems/util';
import { useToast } from '@hems/util/src/composable/toast';
import { BADGE_STATUS_TYPE, LIST_ITEM_MODE, LIST_ITEM_SIZE, TWO_SECONDS_VALUE_IN_MILLISECONDS, } from '@hems/util/src/constant';
import { AxiosErrorException, STATUS_CODE } from '@hems/util/src/exception/exception';
import { createScheduleEventByOperation } from '@hems/util/src/helper/energyManagement/energyManagementHelper';
import { isEmptyArray } from '@hems/util/src/helper/helper';
import { ENERGY_MANAGEMENT_ACTION } from '@hems/util/src/stores/modules/energyManagement';
export default defineComponent({
    name: 'PresetSetting',
    components: {
        EnergyManagementNavigation,
        InputTitle,
        IconButton,
        EmptyListArea,
        List,
    },
    emits: ['moveToPrevPage', 'moveToOperation', 'onEdit'],
    setup(_props, { emit }) {
        const store = useStore();
        const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
        const { t } = useI18n();
        const { siteId, presetInfoList, getPresetOperationsById, updateWeeklySchedulerEventList } = useEnergyManagement();
        const { openToastMessage } = useToast();
        const presetList = computed(() => presetInfoList.value.map((preset) => ({
            ...preset,
            operations: getPresetOperationsById(preset.presetId),
        })));
        const convertPresetToListItem = (list) => {
            return list.map((preset) => ({
                id: preset.presetId,
                onStatus: false,
                activeStatus: false,
                mode: isEmptyArray(preset.operations) ? LIST_ITEM_MODE.BADGE : LIST_ITEM_MODE.DEFAULT,
                size: LIST_ITEM_SIZE.SMALL,
                mainContents: preset.presetName,
                description: isEmptyArray(preset.operations) ? t('control.operation_required') : '',
                checkStatus: false,
                badgeStatus: isEmptyArray(preset.operations) ? BADGE_STATUS_TYPE.ERROR : null,
                iconImageInfo: null,
            }));
        };
        const presetItemList = ref(convertPresetToListItem(presetList.value));
        const toggleActiveStatusOfPresetItem = (presetId, activeStatus) => {
            presetItemList.value = presetItemList.value.map((presetItem) => presetItem.id === presetId ? { ...presetItem, activeStatus } : { ...presetItem, activeStatus: false });
        };
        const findPresetById = (presetId) => presetList.value.find((preset) => preset.presetId === presetId) ?? null;
        const onDetailPreset = (presetId) => {
            toggleActiveStatusOfPresetItem(presetId, true);
            const targetPreset = findPresetById(presetId);
            if (!targetPreset)
                return;
            updateWeeklySchedulerEventList(createScheduleEventByOperation(targetPreset.operations));
        };
        const onEditPreset = (presetId) => {
            emit('onEdit', findPresetById(presetId));
        };
        const operationRequiredMessageModal = useWarningModal({
            title: t('control.check_first_preset_title'),
            content: t('control.check_first_preset_message'),
            confirmButtonText: t('common.ok'),
            confirmCallback: () => emit('moveToPrevPage'),
        });
        const moveToPrevPage = () => {
            const isSomePresetNoOperation = !isEmptyArray(presetList.value) && presetList.value.some((preset) => isEmptyArray(preset.operations));
            if (isSomePresetNoOperation) {
                operationRequiredMessageModal.open();
            }
            else {
                emit('moveToPrevPage');
            }
        };
        const addOperationLater = async (presetId, presetName) => {
            toggleActiveStatusOfPresetItem(presetId, true);
            openToastMessage(t('control.preset_name_added', { presetName }));
            await promiseTimeout(TWO_SECONDS_VALUE_IN_MILLISECONDS);
            toggleActiveStatusOfPresetItem(presetId, false);
        };
        const addPresetSuccessModal = (preset) => {
            const successModal = useConfirmationModal({
                title: t('control.preset_added'),
                content: t('control.preset_added_message', { presetName: preset.presetName }),
                cancelButtonText: t('common.later'),
                confirmButtonText: t('message.add_operation'),
                cancelCallback: () => addOperationLater(preset.presetId, preset.presetName),
                confirmCallback: () => {
                    successModal.close();
                    emit('moveToOperation', preset);
                },
            });
            return successModal;
        };
        const onSavePresetName = withLoading(async (presetName) => {
            if (isNil(siteId.value)) {
                return;
            }
            try {
                addPresetModal.close();
                const presetId = crypto.randomUUID();
                await energyManagementService.createPreset(siteId.value, { id: presetId, name: presetName });
                await store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value);
                const newAddedPreset = presetList.value.find((preset) => preset.presetId === presetId);
                if (newAddedPreset) {
                    addPresetSuccessModal(newAddedPreset).open();
                }
            }
            catch (e) {
                console.error(e);
                if (e instanceof AxiosErrorException) {
                    e.afterError({
                        [STATUS_CODE.INTERNAL_SERVER_ERROR]: () => {
                            openToastMessage(t('message.fail_add_preset'), true);
                        },
                    });
                }
            }
        });
        const addPresetModal = useModal({
            component: PresetNameSettingModal,
            attrs: {
                siteId: siteId.value,
                onConfirm: onSavePresetName,
            },
        });
        const openAddPresetModal = () => {
            addPresetModal.open();
        };
        watch(() => presetInfoList.value, () => {
            presetItemList.value = convertPresetToListItem(presetList.value);
        });
        return {
            presetList,
            presetItemList,
            isEmptyArray,
            onDetailPreset,
            moveToPrevPage,
            openAddPresetModal,
            onEditPreset,
        };
    },
});
