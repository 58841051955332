import { defineComponent, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import pickBy from 'lodash-es/pickBy';
import { Form } from 'vee-validate';
import { DotStep, SettingTable, TermsGroup } from '@hems/component';
import { Helper, TermsHelper } from '@hems/util';
import { OPTIONAL_TERMS_CATEGORY } from '@hems/util/src/constant';
export default defineComponent({
    name: 'Step3OptionalData',
    components: {
        DotStep,
        SettingTable,
        Form,
        TermsGroup,
    },
    props: {
        data: Object,
        locale: {
            type: String,
        },
        termsList: {
            type: Array,
            required: true,
        },
    },
    emits: ['next', 'back'],
    setup(props, { emit }) {
        const store = useStore();
        const appCtx = store.state.appCtx;
        const langCd = computed(() => appCtx.langCd);
        const state = reactive({
            bld_area_val: props.data?.bld_area_val ?? null,
            usage_report: props.data?.usage_report ?? false,
            direct_marketing: props.data?.direct_marketing ?? false,
            termsData: {},
            locale: props.locale ?? appCtx.envLocale,
        });
        const onNext = () => {
            emit('next', 'optionalData', pickBy(state, (v) => !Helper.isNull(v)));
        };
        const setOptionalTerms = () => {
            state.termsData = TermsHelper.getFilteredTerms(props.termsList, state.locale, langCd.value).optionalTerms;
        };
        setOptionalTerms();
        return {
            state,
            OPTIONAL_TERMS_CATEGORY,
            onNext,
            envLocale: appCtx.envLocale,
            getTermsTitleKey: TermsHelper.getTermsTitleKey,
            getAgreementTitleKey: TermsHelper.getAgreementTitleKey,
        };
    },
});
