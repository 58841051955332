import { defineComponent } from 'vue';
import { useLocale, useLanguage } from '@hems/util';
import Image from '@/components/image/Image.vue';
import { APP_STORE_TYPE } from '@/config/ConstantPortal';
const FACEBOOK_URL = process.env.VUE_APP_FACEBOOK_URL;
const INSTAGRAM_URL = process.env.VUE_APP_INSTAGRAM_URL;
const LINKEDIN_URL = process.env.VUE_APP_LINKEDIN_URL;
export default defineComponent({
    name: 'NewFooter',
    components: { Image },
    emits: ['openAppDownloadPopup', 'openPrivacyPopup', 'openCyberSecurityPopup'],
    setup() {
        const { languageCode } = useLanguage();
        const { isEU } = useLocale();
        return {
            APP_STORE_TYPE,
            languageCode,
            isEU,
            FACEBOOK_URL,
            INSTAGRAM_URL,
            LINKEDIN_URL,
        };
    },
});
