import { defineComponent, reactive, computed, watch } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';
import ModuleGrid from './ModuleGrid.vue';
import Pagenation from '../pagenation/Pagenation.vue';
export default defineComponent({
    name: 'ModuleDataGrid',
    components: {
        Pagenation,
        ModuleGrid,
    },
    props: {
        data: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    setup(props) {
        const state = reactive({
            condition: {
                pageNum: 1,
            },
            dividedData: [],
        });
        const maxCountPerPage = 16;
        const totalCnt = computed(() => props.data.length);
        const dataByPaging = computed(() => spliceIntoChunks(props.data, maxCountPerPage));
        function spliceIntoChunks(arr, chunkSize) {
            if (arr === undefined)
                return [];
            const copiedArray = cloneDeep(arr);
            const res = [];
            while (copiedArray.length > 0) {
                const chunk = copiedArray.splice(0, chunkSize);
                res.push(chunk);
            }
            return res;
        }
        watch(() => [dataByPaging, state.condition.pageNum], () => {
            state.dividedData = spliceIntoChunks(dataByPaging.value[state.condition.pageNum - 1], maxCountPerPage / 2);
        }, {
            deep: true,
        });
        return {
            state,
            totalCnt,
            dataByPaging,
            maxCountPerPage,
        };
    },
});
