import { defineComponent, watch, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import isEmpty from 'lodash-es/isEmpty';
import { getACModuleChartConfig, getACModuleChartYAxis, } from '@hems/container/src/forms/device/smartmodule/chartOptions';
import { SmartModuleWebACModuleChartContainer } from '@hems/container/src/highcharts/combination';
import { Helper, DateHelper, useLocale, useRole } from '@hems/util';
import { COLON_HOUR_MIN_FORMAT, GRAPH_TERM_UNIT, SELECT_ALL } from '@hems/util/src/constant';
import { isPeriod } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'SmartModuleWebACSystemChartContainer',
    components: {
        SmartModuleWebACModuleChartContainer,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        selectedDeviceIdList: {
            type: Array,
            default: () => [],
        },
        allDeviceIdList: {
            type: Array,
            default: () => [],
        },
        langCd: {
            type: String,
            default: 'en',
        },
        condition: {
            type: Object,
            required: true,
        },
        timezoneId: {
            type: String,
            default: 'Asia/Seoul',
        },
    },
    setup(props) {
        const { t } = useI18n();
        const { locale } = useLocale();
        const { isDevOrAdmin } = useRole();
        const ACModuleGraphData = ref([]);
        const xAxisCategories = ref([]);
        const yAxis = computed(() => getACModuleChartYAxis(t, isDevOrAdmin));
        const condition = computed(() => props.condition);
        const deviceIdList = computed(() => props.selectedDeviceIdList.length > 0 && props.selectedDeviceIdList[0] === SELECT_ALL
            ? props.allDeviceIdList
            : props.selectedDeviceIdList);
        const xAxisDateFormat = computed(() => props.condition.term_unit === GRAPH_TERM_UNIT.MINUTE
            ? COLON_HOUR_MIN_FORMAT
            : DateHelper.getLocalDateFormat({ locale, isTime: true, isSecond: false }));
        const XAxisValueMap = [
            '00:00',
            '00:15',
            '00:30',
            '00:45',
            '01:00',
            '01:15',
            '01:30',
            '01:45',
            '02:00',
            '02:15',
            '02:30',
            '02:45',
            '03:00',
            '03:15',
            '03:30',
            '03:45',
            '04:00',
            '04:15',
            '04:30',
            '04:45',
            '05:00',
            '05:15',
            '05:30',
            '05:45',
            '06:00',
            '06:15',
            '06:30',
            '06:45',
            '07:00',
            '07:15',
            '07:30',
            '07:45',
            '08:00',
            '08:15',
            '08:30',
            '08:45',
            '09:00',
            '09:15',
            '09:30',
            '09:45',
            '10:00',
            '10:15',
            '10:30',
            '10:45',
            '11:00',
            '11:15',
            '11:30',
            '11:45',
            '12:00',
            '12:15',
            '12:30',
            '12:45',
            '13:00',
            '13:15',
            '13:30',
            '13:45',
            '14:00',
            '14:15',
            '14:30',
            '14:45',
            '15:00',
            '15:15',
            '15:30',
            '15:45',
            '16:00',
            '16:15',
            '16:30',
            '16:45',
            '17:00',
            '17:15',
            '17:30',
            '17:45',
            '18:00',
            '18:15',
            '18:30',
            '18:45',
            '19:00',
            '19:15',
            '19:30',
            '19:45',
            '20:00',
            '20:15',
            '20:30',
            '20:45',
            '21:00',
            '21:15',
            '21:30',
            '21:45',
            '22:00',
            '22:15',
            '22:30',
            '22:45',
            '23:00',
            '23:15',
            '23:30',
            '23:45',
        ];
        const getXAxisCategories = () => {
            const xCategories = [];
            if (isPeriod(condition.value.date)) {
                props.data.forEach((data) => {
                    const convertColecDateToString = DateHelper.getTimestampToTimeZoneDateFormat(data.colec_dt, props.timezoneId, xAxisDateFormat.value);
                    xCategories.push(convertColecDateToString);
                });
            }
            else {
                props.data.forEach((_, index) => {
                    xCategories.push(XAxisValueMap[index]);
                });
            }
            return xCategories;
        };
        const convertTimestampToStringDate = (data) => {
            return data.map((item) => {
                return {
                    ...item,
                    colec_dt: DateHelper.getTimestampToTimeZoneDateFormat(item.colec_dt, props.timezoneId, xAxisDateFormat.value),
                };
            });
        };
        const removeDuplicatedPowerData = (data) => {
            return data.reduce((previousData, current) => {
                if (previousData.findIndex(({ colec_dt }) => colec_dt === current.colec_dt) === -1) {
                    previousData.push(current);
                }
                return previousData;
            }, []);
        };
        const setACModuleDataList = (legendList) => {
            const resultDataList = [];
            const convertData = convertTimestampToStringDate(props.data);
            legendList.forEach((ACModuleList) => {
                ACModuleList.forEach((ACModuleInfo) => {
                    const filterData = removeDuplicatedPowerData(convertData.filter((item) => item.device_id === ACModuleInfo.id));
                    filterData.forEach((data) => ACModuleInfo.data.push(Helper.nullTo(data[ACModuleInfo.yAxis] ?? null, null)));
                    resultDataList.push(ACModuleInfo);
                });
            });
            return resultDataList;
        };
        const setACModuleList = () => {
            const legendList = getACModuleChartConfig(isDevOrAdmin).map((item) => {
                return deviceIdList.value.map((deviceId) => ({
                    ...item,
                    id: deviceId,
                    name: t(item.name) || item.altName,
                    data: [],
                }));
            });
            if (isEmpty(props.data)) {
                return;
            }
            ACModuleGraphData.value = setACModuleDataList(legendList);
        };
        const setACModuleGraphChart = () => {
            xAxisCategories.value = getXAxisCategories();
            setACModuleList();
        };
        watch(() => props.data, () => {
            setACModuleGraphChart();
        });
        return {
            yAxis,
            xAxisCategories,
            deviceIdList,
            ACModuleGraphData,
        };
    },
});
