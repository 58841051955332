import { defineComponent, ref, computed, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import omit from 'lodash-es/omit';
import moment from 'moment';
import { ContentModal, InputTitle, TextInput, ButtonNew, SelectNew, Banner, withLoading } from '@hems/component';
import ScheduleRangeSelector from '@hems/component/src/calendars/ScheduleRangeSelector.vue';
import SaveAsPresetTypeModal from '@hems/container/src/energyManagement/modal/SaveAsPresetTypeModal.vue';
import { EnergyManagementService } from '@hems/service';
import { useEnergyManagement, useScheduleDate, useToast } from '@hems/util';
import { BUTTON_NEW_TYPE } from '@hems/util/src/constant';
import { defaultInputErrorInfo } from '@hems/util/src/helper/helper';
import { ENERGY_MANAGEMENT_ACTION } from '@hems/util/src/stores/modules/energyManagement';
const SCHEDULE_NAME_MAX_LENGTH = 20;
export default defineComponent({
    name: 'ScheduleAddEditModal',
    components: {
        ContentModal,
        InputTitle,
        TextInput,
        ButtonNew,
        SelectNew,
        ScheduleRangeSelector,
        Banner,
    },
    props: {
        siteId: {
            type: [Number, null],
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        scheduleInfo: {
            type: Object,
            default: null,
        },
    },
    emits: ['save', 'delete'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const { scheduleNameList, disabledDateList, scheduleDateList, selectedScheduleInfo, selectedOperationInfo, presetInfoList, getPresetOperationsById, } = useEnergyManagement();
        const store = useStore();
        const { openToastMessage } = useToast();
        const presetList = computed(() => presetInfoList.value.map((preset) => ({
            ...preset,
            operations: getPresetOperationsById(preset.presetId),
        })));
        const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
        const scheduleName = ref(props.isEdit ? selectedScheduleInfo.value?.name || '' : '');
        const scheduleNameErrorInfo = ref(defaultInputErrorInfo);
        const { convertScheduleDateRange, getDateRangeDisabledDateList } = useScheduleDate();
        const dateRangeDisabledDateList = computed(() => getDateRangeDisabledDateList(props.isEdit ? disabledDateList.value : scheduleDateList.value));
        const getScheduleDateRangeInitialValue = () => {
            return props.isEdit && selectedScheduleInfo.value
                ? convertScheduleDateRange(selectedScheduleInfo.value?.startDate, selectedScheduleInfo.value?.endDate)
                : { start: null, end: null };
        };
        const scheduleDateRange = ref(getScheduleDateRangeInitialValue());
        const selectedPresetId = ref(null);
        const presetOptionList = computed(() => {
            const noneGroup = { groupTitle: '', items: [{ text: t('common.none'), value: null }] };
            // TBD
            // const basicPresetGroup = {
            //   groupTitle: t('control.basic_preset'),
            //   items:[]
            // };
            const myPreset = {
                groupTitle: t('control.my_preset'),
                items: presetList.value
                    .filter((preset) => preset.operations.length)
                    .map((preset) => ({
                    text: preset.presetName,
                    value: preset.presetId,
                })),
            };
            if (!myPreset.items.length) {
                return [noneGroup];
            }
            return [noneGroup, myPreset];
        });
        const isSaveButtonDisabled = computed(() => {
            return (!scheduleName.value ||
                scheduleNameErrorInfo.value.isError ||
                !scheduleDateRange.value.start ||
                !scheduleDateRange.value.end);
        });
        const hasOperations = computed(() => (selectedScheduleInfo.value?.operations?.length || 0) > 0);
        const checkScheduleNameDuplicate = (scheduleName) => {
            if (props.isEdit && scheduleName === selectedScheduleInfo.value?.name) {
                return false;
            }
            return scheduleNameList.value.includes(scheduleName);
        };
        const checkIsValidate = (name) => {
            if (name.length === 0) {
                return false;
            }
            if (name.length > SCHEDULE_NAME_MAX_LENGTH) {
                scheduleNameErrorInfo.value = {
                    isError: true,
                    errorMessage: t('message.maximum_characters_length', { length: SCHEDULE_NAME_MAX_LENGTH }),
                };
                return false;
            }
            const isDuplicate = checkScheduleNameDuplicate(scheduleName.value);
            if (isDuplicate) {
                scheduleNameErrorInfo.value = {
                    isError: true,
                    errorMessage: t('message.name_already_exists'),
                };
                return false;
            }
            scheduleNameErrorInfo.value = defaultInputErrorInfo;
            return true;
        };
        const onSave = () => {
            const scheduleFormValue = {
                name: scheduleName.value,
                startDate: moment(scheduleDateRange.value.start).format('MM-DD'),
                endDate: moment(scheduleDateRange.value.end).format('MM-DD'),
                isSaveRequired: true,
            };
            if (props.isEdit) {
                emit('save', { scheduleInfo: scheduleFormValue, selectedPresetId: null });
            }
            else {
                emit('save', { scheduleInfo: scheduleFormValue, selectedPresetId: selectedPresetId.value });
            }
        };
        const onDelete = () => {
            emit('delete');
        };
        const convertPresetOperationInfo = (operations) => {
            return operations.map((operation) => omit(operation, 'operationId'));
        };
        const reloadPreset = (siteId) => {
            store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId);
        };
        const savePresetWithOperations = withLoading(async (siteId, presetName, operations) => {
            try {
                await energyManagementService.createPreset(siteId, {
                    name: presetName,
                    operations: convertPresetOperationInfo(operations),
                });
                reloadPreset(siteId);
                openToastMessage(t('message.schedule_is_saved_as_preset', { name: scheduleName.value }));
            }
            catch (error) {
                console.log(error);
                openToastMessage(t('message.fail_save_preset'));
            }
        });
        const savePresetModal = useModal({
            component: SaveAsPresetTypeModal,
            attrs: {
                scheduleName: scheduleName.value,
                siteId: props.siteId,
                onSave: async (presetName) => {
                    if (!props.siteId) {
                        return;
                    }
                    await savePresetWithOperations(props.siteId, presetName, selectedOperationInfo.value?.operations);
                    savePresetModal.close();
                },
                onCancel: () => {
                    savePresetModal.close();
                },
            },
        });
        const onSaveAsPreset = () => {
            savePresetModal.open();
        };
        watch(() => selectedScheduleInfo.value, () => {
            scheduleDateRange.value = getScheduleDateRangeInitialValue();
        });
        return {
            scheduleName,
            scheduleDateRange,
            selectedPresetId,
            presetOptionList,
            SCHEDULE_NAME_MAX_LENGTH,
            isSaveButtonDisabled,
            scheduleNameErrorInfo,
            hasOperations,
            disabledDateList,
            scheduleDateList,
            BUTTON_NEW_TYPE,
            checkIsValidate,
            onSave,
            onSaveAsPreset,
            onDelete,
            dateRangeDisabledDateList,
            selectedScheduleInfo,
        };
    },
});
