import { defineComponent } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
export default defineComponent({
    name: 'DropdownMenu',
    components: { Image2 },
    props: {
        selectedValue: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['click'],
});
