export const BANNER_TYPE = {
    DEFAULT: 'default',
    SUCCESS: 'success',
    WARNING: 'warning',
    NOTICE: 'notice',
};
export const BANNER_ICON_MAP = {
    [BANNER_TYPE.DEFAULT]: 'icons/ic_re_info',
    [BANNER_TYPE.SUCCESS]: 'icons/ic_check_circle_line_primary',
    [BANNER_TYPE.WARNING]: 'icons/ic_error',
    [BANNER_TYPE.NOTICE]: 'icons/ic_info',
};
