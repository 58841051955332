export const INPUT_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    PASSWORD: 'password',
    HIDDEN: 'hidden',
};
export const INPUT_VALIDATE_MODE = {
    INPUT: 'input',
    BLUR: 'blur',
};
export const INPUT_VALID_CLASS = {
    DEFAULT: '',
    VALID: 'ok',
    ERROR: 'error',
};
export const INPUT_CLASS_NAME = {
    SMALL: 'fm_ipt',
    MEDIUM: 'fm_ipt_2',
    LARGE: 'fm_ipt_4',
};
