import { APP_TYPE } from '@hems/util/src/constant';
// eslint-disable-next-line import/prefer-default-export
export const useAppType = () => {
    const appType = process.env.VUE_APP_TYPE;
    const isAdminApp = appType === APP_TYPE.ADMIN;
    const isInstallerApp = appType === APP_TYPE.INSTALLER;
    const isUserApp = appType === APP_TYPE.USER;
    const isPortalApp = appType === APP_TYPE.PORTAL;
    return {
        appType,
        isPortalApp,
        isAdminApp,
        isInstallerApp,
        isUserApp,
    };
};
