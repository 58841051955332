import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { LabelInput, Searchbox } from '@hems/component';
import { CommonService } from '@hems/service';
import { Helper, useLocale } from '@hems/util';
import { MONITORING_LIST_TAB, SETTING_TAB_CODE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'MonitoringSearchBox',
    components: {
        LabelInput,
        Searchbox,
    },
    props: {
        condition: {
            type: Object,
            required: true,
        },
        isFcas: {
            type: Boolean,
            default: false,
        },
        role: {
            type: String,
            required: true,
        },
        isSapn: {
            type: Boolean,
            default: false,
        },
        monitoringListTabName: {
            type: String,
            required: true,
        },
    },
    emits: ['search'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const { isAU, isUS } = useLocale();
        const commService = new CommonService(window.axiosInstance.axios);
        const tabName = computed(() => props.monitoringListTabName);
        const isSearchBoxShow = computed(() => (isAU || isUS) && tabName.value === SETTING_TAB_CODE.SITE);
        const isLFDIShow = computed(() => isAU && tabName.value === SETTING_TAB_CODE.SITE);
        const isEMSList = computed(() => tabName.value === MONITORING_LIST_TAB.EMS);
        const state = reactive({
            hide: true,
            condition: { ...props.condition },
            productModelNms: props.condition.productModelNm ? props.condition.productModelNm.split(',') : [],
            placeHolder: 'If not selected, search All',
        });
        const { PRODUCT_MODEL_CD } = await commService.getCodesByGroupCode([
            {
                grpCd: 'PRODUCT_MODEL_CD',
                exclude: [
                    'ELSR362-00002',
                    'ELSR362-00003',
                    'ELSR362-00004',
                    'ELSR362-00005',
                    'ELSR722-00004',
                    'ELSR103-00001',
                    'ELSR552-00001',
                    'ELSR362-00001',
                    'ELSR802-00002',
                    'ELSR552-00008',
                    'ELSR362-00008',
                    'ELSR802-00008',
                    'ELSR552-00005',
                    'ELSR362-00006',
                    'ELSR552-00006',
                ],
            },
        ]);
        function convertOptions(codeNames) {
            const selectAll = { text: t('common.all'), value: '' };
            return Helper.addSelectorOptionAtFirst(Helper.codeNamesToSelectorOptions(codeNames, t), selectAll);
        }
        function convertLabelOptions(codeNames) {
            return Helper.codeNamesToLabelSelectorOptions(codeNames, t);
        }
        function onSearch() {
            state.condition.searchFor = state.condition.searchFor?.trim();
            state.condition.productModelNm = state.productModelNms.join(',');
            const { searchFor, productModelNm, emsVer, pcsVer, directMarketingTerms, offGrid, autoFwUpdateFlag, sapnFlag } = state.condition;
            emit('search', {
                searchFor,
                productModelNm,
                emsVer,
                pcsVer,
                directMarketingTerms,
                offGrid,
                autoFwUpdateFlag,
                sapnFlag,
            });
        }
        return {
            state,
            onSearch,
            productModelList: computed(() => convertLabelOptions(PRODUCT_MODEL_CD || [])),
            directMarketingList: convertOptions([
                { code: 'Y', name: 'Y' },
                { code: 'N', name: 'N' },
            ]),
            gridStatusList: convertOptions([
                { code: 'Y', name: 'Y' },
                { code: 'N', name: 'N' },
            ]),
            autoFwUpdateList: convertOptions([
                { code: '1', name: 'Enable' },
                { code: '0', name: 'Disable' },
            ]),
            sapnFlagList: convertOptions([
                { code: 'SAPN', name: 'Success' },
                { code: 'FAIL', name: 'Fail' },
                { code: 'NO', name: 'Not Target' },
            ]),
            placeholder: computed(() => !['user', 'installer'].includes(props.role)
                ? props.isFcas
                    ? t('message.enter_search_word').concat(` (${[
                        t('device.serial_no'),
                        t('common.site_id'),
                        t('account.owner_name'),
                        t('account.installer_id'),
                        `NMI ${t('common.number')}`,
                    ].join(', ')})`)
                    : t('message.enter_search_word').concat(` (${[
                        t('device.serial_no'),
                        t('common.site_id'),
                        t('account.owner_name'),
                        t('account.installer_id'),
                    ].join(', ')})`)
                : t('message.enter_search_word').concat(` (${[t('device.serial_no'), t('common.site_id'), t('account.owner_name')].join(', ')})`)),
            tabName,
            isSearchBoxShow,
            isLFDIShow,
            isEMSList,
        };
    },
});
