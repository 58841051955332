/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
import { DEFAULT_LANGUAGE, ENV_LOCALE } from '@hems/util/src/constant';
// eslint-disable-next-line import/prefer-default-export
export function useLocale() {
    const locale = process.env.VUE_APP_LOCALE ?? ENV_LOCALE.EU;
    const isEU = locale === ENV_LOCALE.EU;
    const isAU = locale === ENV_LOCALE.AU;
    const isUS = locale === ENV_LOCALE.US;
    const store = useStore();
    const intlFormatLocale = computed(() => store.state.appCtx.locale ?? DEFAULT_LANGUAGE);
    return {
        locale,
        isEU,
        isAU,
        isUS,
        intlFormatLocale,
    };
}
