import { computed, defineAsyncComponent, defineComponent, inject, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';
import { useMessageBox, usePromptPopup, withLoading } from '@hems/component';
import { AuthService, JoinService, UserInfoService } from '@hems/service';
import { useLanguage, useLocale, AuthHelper, Helper, DateHelper, LocalStorageHelper } from '@hems/util';
import { APP_MODE, ENV_LOCALE, PROVIDE_INJECT_KEYS, localStorageKey } from '@hems/util/src/constant';
import { UnauthorizedException, AccessTokenParseException, SocialLoginException, UnknownErrorException, AxiosErrorException, } from '@hems/util/src/exception/exception';
import { getCurrentTimestamp } from '@hems/util/src/helper/dateHelper';
import NewGoogleLoginButton from '@/components/button/NewGoogleLoginButton.vue';
import NewHeader from '@/components/header/NewHeader.vue';
import Image from '@/components/image/Image.vue';
import { useAuthentication } from '@/composables/authentication';
import { useDashboard } from '@/composables/dashboard';
import { AUTH_CREDENTIAL_INFO, JOIN_TYPE, LOGIN_PROVIDER } from '@/config/ConstantPortal';
import { getCountryList } from '@/config/countryConfig';
const MAX_RETRY_LOGIN_CNT = 3;
const RETRY_LOGIN_LOG_FLAG = [APP_MODE.LOCAL, APP_MODE.DEVELOPMENT, APP_MODE.STAGING].includes(Helper.getAppEnv());
export default defineComponent({
    name: 'Login',
    components: {
        NewHeader,
        Image,
        NewGoogleLoginButton,
        JoinPopupContainer: defineAsyncComponent(() => import('@/containers/popup/JoinPopupContainer.vue')),
        JoinTypeSelectionPopup: defineAsyncComponent(() => import('@/components/popup/join/JoinTypeSelectionPopup.vue')),
        FindIdPwPopupContainer: defineAsyncComponent(() => import('@/containers/popup/FindIdPwPopupContainer.vue')),
    },
    setup() {
        const { languageCode } = useLanguage();
        const { locale } = useLocale();
        const store = useStore();
        const router = useRouter();
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const { setTokenInfo } = useAuthentication();
        const { goDashboardPage } = useDashboard();
        const LocalStorageService = LocalStorageHelper.default;
        const facebookOAuth = inject(PROVIDE_INJECT_KEYS.FACEBOOK_OAUTH);
        const appleOAuth = inject(PROVIDE_INJECT_KEYS.APPLE_OAUTH);
        const googleOAuth = inject(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH);
        const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL });
        const loginService = new AuthService(axiosInstance);
        const validService = new JoinService(axiosInstance);
        const userInfoService = new UserInfoService(axiosInstance);
        const idInputRef = ref(null);
        const idInput = ref('');
        const passwordInput = ref('');
        const showPassword = ref(false);
        const findIdPwType = ref(AUTH_CREDENTIAL_INFO.ID);
        const joinType = ref(JOIN_TYPE.GENERAL);
        const joinLocale = ref(locale);
        const joinSocialInfo = ref(null);
        const { envLocale, termsVer } = store.state.appCtx;
        let loginRetryCount = 0;
        const passingRoles = (process.env.VUE_APP_ROLES_THAT_DONT_REQUIRE_AGREEMENT_TO_TERMS || '').split(',');
        let termVersion = `${termsVer}-${envLocale}`;
        const countryState = reactive({
            countryList: computed(() => getCountryList().map((item) => ({ text: t(item.text), value: item.value }))),
            countrySelectionPopup: usePromptPopup(),
            // TODO countryCode 상수화
            countryCode: envLocale === ENV_LOCALE.EU ? 'DE' : envLocale === ENV_LOCALE.US ? 'US' : 'AU',
        });
        const LOGIN_PAGE_POPUP = {
            JOIN_TYPE_SELECTION: 'JOIN_TYPE_SELECTION',
            JOIN: 'JOIN',
            FIND_ID_PW: 'FIND_ID_PW',
        };
        const popupState = reactive({
            isOpenJoinTypeSelection: false,
            isOpenJoin: false,
            isOpenFindIdPw: false,
        });
        const popupTypePopupOpenMethodNameMap = {
            [LOGIN_PAGE_POPUP.JOIN_TYPE_SELECTION]: 'isOpenJoinTypeSelection',
            [LOGIN_PAGE_POPUP.JOIN]: 'isOpenJoin',
            [LOGIN_PAGE_POPUP.FIND_ID_PW]: 'isOpenFindIdPw',
        };
        const goBack = () => {
            router.go(-1);
        };
        const toggleShowPassword = () => {
            showPassword.value = !showPassword.value;
        };
        const openPopup = (popupType) => {
            popupState[popupTypePopupOpenMethodNameMap[popupType]] = true;
        };
        const closePopup = (popupType) => {
            popupState[popupTypePopupOpenMethodNameMap[popupType]] = false;
        };
        const openFindIdPwPopup = (type) => {
            findIdPwType.value = type;
            openPopup(LOGIN_PAGE_POPUP.FIND_ID_PW);
        };
        const onJoinType = (socialInfo) => {
            joinSocialInfo.value = socialInfo;
            openPopup(LOGIN_PAGE_POPUP.JOIN_TYPE_SELECTION);
        };
        const setJoinType = (selectedJoinType, localeAU) => {
            closePopup(LOGIN_PAGE_POPUP.JOIN_TYPE_SELECTION);
            joinType.value = selectedJoinType;
            joinLocale.value = localeAU ?? locale;
            openPopup(LOGIN_PAGE_POPUP.JOIN);
        };
        const isSuccess = (resData) => {
            return resData.access_token ?? false;
        };
        const addRetryLoginLogMessage = async (issuer, id, pwd, token) => {
            const logMessage = {
                token,
                id,
                pwd: issuer === LOGIN_PROVIDER.QOMMAND ? pwd : `login by ${issuer}`,
                time: DateHelper.formatToday('YYYY/MM/DD HH:mm:ss'),
            };
            await loginService.addRetryLoginLog(logMessage);
        };
        const checkLoginFailedExpired = (id) => {
            const loginFailedItem = LocalStorageService.getOneItem(localStorageKey.loginFailed, id);
            if (loginFailedItem) {
                if (loginFailedItem.expireTime) {
                    const isExpired = getCurrentTimestamp() > new Date(loginFailedItem.expireTime).getTime();
                    return isExpired;
                }
                return null;
            }
            return null;
        };
        const insertNewLoginFailedItem = (id) => {
            const newLoginFailedItem = {
                id,
                count: 1,
            };
            LocalStorageService.insertOneItem(localStorageKey.loginFailed, newLoginFailedItem);
        };
        const updateLoginFailed = (id) => {
            // 전체 로그인 실패 정보
            const loginFailedItems = LocalStorageService.get(localStorageKey.loginFailed);
            // 로그인 시도한 계정에 대한 로그인 실패 정보
            const loginFailedItem = LocalStorageService.getOneItem(localStorageKey.loginFailed, id);
            if (loginFailedItems) {
                if (loginFailedItem) {
                    const updatedLoginFailedItem = {
                        ...loginFailedItem,
                        count: loginFailedItem.count + 1,
                    };
                    if (loginFailedItem.count < 4) {
                        LocalStorageService.updateOneItem(localStorageKey.loginFailed, id, updatedLoginFailedItem);
                    }
                    else {
                        const date = new Date();
                        updatedLoginFailedItem.expireTime = new Date(date.getTime() + 60000).toString();
                        LocalStorageService.updateOneItem(localStorageKey.loginFailed, id, updatedLoginFailedItem);
                    }
                }
                else {
                    insertNewLoginFailedItem(id);
                }
            }
            else {
                // 어떤 계정에 대해서도 로그인 실패 정보가 없는 경우
                insertNewLoginFailedItem(id);
            }
        };
        const removeLoginFailedItem = (id) => {
            LocalStorageService.removeOneItem(localStorageKey.loginFailed, id);
        };
        const onLogin = withLoading(
        // FIXME complexity of 22...
        // eslint-disable-next-line complexity
        async ({ username, password }, afterLoginCallback) => {
            try {
                const isLoginFailedExpired = checkLoginFailedExpired(username);
                if (isLoginFailedExpired !== null && !isLoginFailedExpired) {
                    messageBox.alert([t('message.login_failed_5times'), t('message.try_again_1minute')]).open();
                    return;
                }
                const resData = await loginService.getAccessToken({
                    username,
                    password,
                });
                removeLoginFailedItem(username);
                if (!isSuccess(resData)) {
                    throw new UnauthorizedException();
                }
                const jwtObject = AuthHelper.parseJwt(resData.access_token);
                if (!jwtObject) {
                    throw new AccessTokenParseException(resData.access_token);
                }
                // 토큰 값에 auth_type_cd가 없으면, 로그인 재시도
                if (!jwtObject.auth_type_cd) {
                    if (loginRetryCount >= MAX_RETRY_LOGIN_CNT) {
                        loginRetryCount = 0;
                        messageBox.alert(t('message.retry_later')).open();
                        return;
                    }
                    loginRetryCount += 1;
                    if (RETRY_LOGIN_LOG_FLAG) {
                        addRetryLoginLogMessage(LOGIN_PROVIDER.QOMMAND, username, password, resData.access_token);
                    }
                    onLogin({ username, password });
                    return;
                }
                // 1. 약관 동의가 불필요한 role의 경우, 바로 로그인 처리
                const roleNm = AuthHelper.getRoleNm(jwtObject.auth_type_cd);
                if (passingRoles.includes(roleNm)) {
                    afterLoginCallback(resData.access_token);
                    return;
                }
                // 계정의 국가 정보 확인
                const accountCountryInfo = await userInfoService.getAccountCountryInfo(username, resData.access_token);
                if (!accountCountryInfo || Helper.isNull(accountCountryInfo.cntry_cd)) {
                    // 계정 국가 정보가 없다면 국가 선택 UI 팝업 띄움
                    const isOk = await countryState.countrySelectionPopup.open();
                    if (isOk) {
                        // 국가 정보 업데이트
                        await userInfoService.updateAccountCountryInfo({ userId: username, cntryCd: countryState.countryCode }, resData.access_token);
                    }
                }
                else {
                    countryState.countryCode = accountCountryInfo?.cntry_cd ?? '';
                }
                // 국가가 뉴질랜드인 경우 nz-termsVer처리
                if (envLocale === ENV_LOCALE.AU && countryState.countryCode === ENV_LOCALE.NZ) {
                    termVersion = `${process.env.VUE_APP_TERMS_VER_NZ}-NZ`;
                }
                // 2. 약관 재동의 여부 확인
                const checkTermVersion = await loginService.checkTermsVersion({
                    ver: termVersion,
                }, resData.access_token);
                // TODO 약관 재동의 기능 별도 업무로 진행
                // if (!checkTermVersion.is_confirmed) {
                // emit('reagree', resData.access_token, termVersion);
                // } else {
                afterLoginCallback(resData.access_token);
                // }
            }
            catch (e) {
                // 로그인 실패 만료 시간이 지났을 경우 실패 정보 삭제
                const isLoginFailedExpired = checkLoginFailedExpired(username);
                if (isLoginFailedExpired)
                    removeLoginFailedItem(username);
                if (e instanceof UnauthorizedException) {
                    await messageBox.alert(t('message.incorrect_idpw_tryagain')).open();
                    // 로그인 실패 횟수 체크
                    updateLoginFailed(username);
                    return;
                }
                if (e instanceof AccessTokenParseException) {
                    messageBox.alert([t('message.unknown_error'), '[error: token parser]']).open();
                    return;
                }
                if (e instanceof AxiosErrorException) {
                    const isRun = e.afterError({
                        400: async () => {
                            await messageBox.alert(t('message.incorrect_idpw_tryagain')).open();
                            // 로그인 실패 횟수 체크
                            updateLoginFailed(username);
                        },
                        401: async () => {
                            await messageBox.alert(t('message.incorrect_idpw_tryagain')).open();
                            // 로그인 실패 횟수 체크
                            updateLoginFailed(username);
                        },
                        500: async () => {
                            await messageBox.alert(t('message.login_server_error')).open();
                        },
                        503: async () => {
                            await messageBox.alert(t('message.login_server_error')).open();
                        },
                    });
                    if (isRun)
                        return;
                }
                messageBox.alert(t('message.unknown_error')).open();
            }
        });
        const onSocialLogin = withLoading(
        // FIXME complexity of 32...
        // eslint-disable-next-line complexity
        async (issuer, issuerToken, email, name, afterLoginCallback) => {
            try {
                // 1. 가입여부 체크
                try {
                    const data = await validService.checkUserIdEmail(email);
                    if (typeof data.result.is_valid !== 'boolean') {
                        messageBox.alert(t('message.retry_later')).open();
                        return;
                    }
                    const isExist = !data.result.is_valid;
                    if (!isExist) {
                        // 미가입 이메일일 경우,
                        throw new SocialLoginException();
                    }
                    else {
                        // 가입된 이메일이지만 일반 가입자인 경우
                        if (![LOGIN_PROVIDER.GOOGLE, LOGIN_PROVIDER.FACEBOOK, LOGIN_PROVIDER.APPLE].includes(data.result.issuer)) {
                            messageBox.alert(t('message.exist_member', { email })).open();
                            return;
                        }
                        // 가입된 이메일이지만 issuer가 다를 경우
                        if (data.result.issuer !== issuer) {
                            messageBox
                                .alert(t('message.exist_social_account', { email, issuer: Helper.capitalize(data.result.issuer) }))
                                .open();
                            return;
                        }
                    }
                }
                catch (e) {
                    if (e instanceof SocialLoginException)
                        throw e;
                    throw new UnknownErrorException();
                }
                // 2. 토큰 생성
                let resData;
                try {
                    resData = await loginService.getAccessTokenBySocialToken(issuer, issuerToken, email);
                    if (!isSuccess(resData)) {
                        throw new UnknownErrorException();
                    }
                }
                catch (e) {
                    if (e instanceof AxiosErrorException) {
                        const isRun = e.afterError({
                            500: async () => {
                                await messageBox.alert(t('message.login_server_error')).open();
                            },
                            503: async () => {
                                await messageBox.alert(t('message.login_server_error')).open();
                            },
                        });
                        if (isRun)
                            return;
                    }
                    messageBox.alert(t('message.retry_later')).open();
                    return;
                }
                const jwtObject = AuthHelper.parseJwt(resData.access_token);
                if (!jwtObject) {
                    throw new AccessTokenParseException(resData.access_token);
                }
                // 3. 토큰 값에 auth_type_cd가 없으면, 로그인 재시도
                if (!jwtObject.auth_type_cd) {
                    if (loginRetryCount >= MAX_RETRY_LOGIN_CNT) {
                        loginRetryCount = 0;
                        messageBox.alert(t('message.retry_later')).open();
                        return;
                    }
                    loginRetryCount += 1;
                    if (RETRY_LOGIN_LOG_FLAG) {
                        addRetryLoginLogMessage(issuer, email, '', resData.access_token);
                    }
                    onSocialLogin(issuer, issuerToken, email, name, afterLoginCallback);
                    return;
                }
                // 4. 약관 재동의
                // 4-1. 약관 재동의가 불필요한 role의 경우, 바로 로그인 처리
                const roleNm = AuthHelper.getRoleNm(jwtObject.auth_type_cd);
                if (passingRoles.includes(roleNm)) {
                    afterLoginCallback(resData.access_token);
                    return;
                }
                // 계정의 국가 정보 확인
                const accountCountryInfo = await userInfoService.getAccountCountryInfo(email, resData.access_token);
                if (!accountCountryInfo || Helper.isNull(accountCountryInfo.cntry_cd)) {
                    // 계정 국가 정보가 없다면 국가 선택 UI 팝업 띄움
                    const isOk = await countryState.countrySelectionPopup.open();
                    if (isOk) {
                        // 국가 정보 업데이트
                        await userInfoService.updateAccountCountryInfo({ userId: email, cntryCd: countryState.countryCode }, resData.access_token);
                    }
                }
                else {
                    countryState.countryCode = accountCountryInfo?.cntry_cd ?? '';
                }
                // 국가가 뉴질랜드인 경우 nz-termsVer처리
                if (countryState.countryCode === 'NZ') {
                    termVersion = `${process.env.VUE_APP_TERMS_VER_NZ}-NZ`;
                }
                // 4-2. 약관 재동의 여부 확인
                const checkTermVersion = await loginService.checkTermsVersion({
                    ver: termVersion,
                }, resData.access_token);
                // TODO 약관 재동의 기능 별도 업무로 진행
                // if (!checkTermVersion.is_confirmed) {
                // emit('reagree', resData.access_token, termVersion);
                // } else {
                afterLoginCallback(resData.access_token);
                // }
            }
            catch (e) {
                if (issuer === LOGIN_PROVIDER.FACEBOOK && facebookOAuth && (await facebookOAuth.isLogin())) {
                    facebookOAuth.logout();
                }
                else if (issuer === LOGIN_PROVIDER.GOOGLE && googleOAuth && (await googleOAuth.isLogin())) {
                    googleOAuth.logout();
                }
                if (e instanceof SocialLoginException) {
                    // 소셜 로그인 실패 시 계정 없는 것으로 간주, 소셜계정으로 회원 가입
                    if (await messageBox.confirm([t('message.unreg_account', { email }), t('message.join')]).open()) {
                        // TODO flow 확인해보기
                        onJoinType({ issuer, issuerToken, email, name });
                    }
                    return;
                }
                if (e instanceof AccessTokenParseException) {
                    messageBox.alert([t('message.unknown_error'), '[error: token parser]']).open();
                    return;
                }
                messageBox.alert(t('message.unknown_error')).open();
            }
        });
        const afterLogin = withLoading(async (token) => {
            await setTokenInfo(token);
            goDashboardPage();
        });
        const socialLogin = (issuer, issuerToken, email, name) => {
            if (Helper.isNull(email)) {
                messageBox.alert(t('message.email_registration_required')).open();
                return;
            }
            if (email.length > 50) {
                messageBox.alert(t('message.too_long_email')).open();
                return;
            }
            onSocialLogin(issuer, issuerToken, email, name, afterLogin);
        };
        const googleLogin = async () => {
            let accessToken;
            let email;
            let name;
            if (!googleOAuth) {
                return;
            }
            try {
                accessToken = googleOAuth.getAccessToken();
                email = googleOAuth.getEmail();
                name = googleOAuth.getName();
                // 부득이하게 타입 에러 해제. 추후 개선 필요
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                socialLogin(LOGIN_PROVIDER.GOOGLE, accessToken, email, name);
            }
            catch (e) {
                console.error(e);
            }
        };
        const facebookLogin = async () => {
            let accessToken;
            let email;
            let name;
            if (!facebookOAuth) {
                return;
            }
            try {
                await facebookOAuth.login();
                accessToken = await facebookOAuth.getAccessToken();
                email = facebookOAuth.getEmail();
                name = facebookOAuth.getName();
                accessToken && email && name && socialLogin(LOGIN_PROVIDER.FACEBOOK, accessToken, email, name);
            }
            catch (e) {
                console.error(e);
            }
        };
        const appleLogin = async () => {
            let accessToken;
            let email;
            let name;
            if (!appleOAuth) {
                return;
            }
            try {
                await appleOAuth.login();
                accessToken = appleOAuth.getAccessToken();
                email = appleOAuth.getEmail();
                name = appleOAuth.getName();
                accessToken && email && name && socialLogin(LOGIN_PROVIDER.APPLE, accessToken, email, name);
            }
            catch (e) {
                console.error(e);
            }
        };
        const login = () => {
            if (Helper.isNull(idInput.value) || Helper.isNull(passwordInput.value)) {
                messageBox.alert(t('message.invalid_id_pw')).open();
                return;
            }
            onLogin({ username: idInput.value, password: passwordInput.value }, afterLogin);
        };
        onMounted(() => {
            idInputRef.value?.focus();
        });
        return {
            idInputRef,
            idInput,
            passwordInput,
            languageCode,
            showPassword,
            findIdPwType,
            locale,
            popupState,
            joinType,
            joinLocale,
            joinSocialInfo,
            JOIN_TYPE,
            LOGIN_PAGE_POPUP,
            AUTH_CREDENTIAL_INFO,
            LOGIN_PROVIDER,
            onJoinType,
            setJoinType,
            goBack,
            toggleShowPassword,
            openPopup,
            closePopup,
            openFindIdPwPopup,
            login,
            googleLogin,
            facebookLogin,
            appleLogin,
        };
    },
});
