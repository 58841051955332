import { DeviceService } from '@hems/service/src/api/device/DeviceService';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class DeviceServiceAdmin extends DeviceService {
    constructor(axios) {
        super(axios);
    }
    getRecentInstalledDeviceList = async (pageNum) => {
        return await this.get(apiWrapper.adminAPI('/main/prod/list'), { pageNum });
    };
    changeInstaller = async (siteId, changeInstallerRequestBody) => {
        return await this.put(apiWrapper.managerApi(`/devices/profiles/site/${siteId}/installers`), changeInstallerRequestBody);
    };
    deleteDeviceForACSystem = async (siteId) => {
        return this.delete(apiWrapper.managerApi(`/devices/installations/sqa/delete/${siteId}`));
    };
}
