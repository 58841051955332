import { PROVIDE_INJECT_KEYS } from '../../constant';
import * as AuthHelper from '../../helper/authHelper';
export class Vue3AppleOAuth2 {
    _auth;
    _email;
    constructor(options) {
        (function (doc, tagNm, id) {
            const fjs = doc.getElementsByTagName(tagNm)[0];
            if (doc.getElementById(id))
                return;
            const jsEl = doc.createElement(tagNm);
            jsEl.id = id;
            jsEl.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
            jsEl.onload = function () {
                AppleID.auth.init(options);
            };
            fjs.parentNode?.insertBefore(jsEl, fjs);
            // document.addEventListener('AppleIDSignInOnSuccess', (data) => {
            //   //handle successful response
            // });
        })(document, 'script', 'apple-apisdk');
    }
    login() {
        return new Promise((resolve, reject) => {
            AppleID.auth
                .signIn()
                .then((data) => {
                this._auth = data;
                resolve(data);
            })
                .catch((e) => reject(e));
        });
    }
    logout() {
        if (this._auth)
            this._auth = undefined;
        if (this._email)
            this._email = undefined;
        return true;
    }
    getAccessToken() {
        if (!this._auth)
            return;
        return this._auth.authorization?.id_token;
    }
    getEmail() {
        if (!this._auth)
            return;
        if (!this._email) {
            this._email = AuthHelper.parseJwt(this.getAccessToken())?.email;
        }
        return this._email;
    }
    getName() {
        if (!this._auth)
            return;
        if (!this._auth.user)
            return;
        const { firstName, lastName } = this._auth.user.name;
        return `${firstName} ${lastName}`;
    }
    isLogin() {
        return !!this._auth;
    }
}
const Vue3AppleOAuth2Plugin = {
    install: (app, options) => {
        // set config
        const defaultConfig = { scope: 'name email', state: 'x login' };
        let config;
        if (typeof options === 'object') {
            config = Object.assign({}, defaultConfig, options);
            if (!options.clientId) {
                throw new Error('clientId is required');
            }
        }
        else {
            throw new TypeError('invalid option type. Object type accepted only');
        }
        // Install Vue plugin
        const instance = new Vue3AppleOAuth2(config);
        app.config.globalProperties.$appleOAuth = instance;
        app.provide(PROVIDE_INJECT_KEYS.APPLE_OAUTH, instance);
    },
};
export default Vue3AppleOAuth2Plugin;
