import { defineComponent, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import PopupWrapper from '@hems/component/src/popup/PopupWrapper.vue';
import { Helper, DateHelper } from '@hems/util';
const REMOTE_UPDATE_RESPONSE_CODE = {
    SUCCESS: 'success',
    IN_PROGRESS: 'in_progress',
    FAIL: 'fail',
};
export default defineComponent({
    name: 'ProcessResultPopup',
    components: { PopupWrapper },
    props: {
        processResult: {
            type: Array,
            required: true,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const uuid = Helper.getUUID();
        const store = useStore();
        const state = reactive({
            langCd: computed(() => store.state.appCtx.langCd),
            data: computed(() => transformData(props.processResult)),
        });
        const transformData = (data) => {
            const dateFormatConfig = { isTime: true, needUtcConvert: true };
            return data.map((item) => [
                {
                    response_dt_utc: item.response_dt ? DateHelper.getLocalDate(item.response_dt, dateFormatConfig) : '',
                },
            ]);
        };
        const closePopup = () => {
            emit('close');
        };
        const REMOTE_UPDATE_RESPONSE_CODE_MAP = {
            [REMOTE_UPDATE_RESPONSE_CODE.SUCCESS]: { class: 'ty_1', name: 'common.success' },
            [REMOTE_UPDATE_RESPONSE_CODE.IN_PROGRESS]: { class: 'ty_2', name: 'common.in_progress' },
            [REMOTE_UPDATE_RESPONSE_CODE.FAIL]: { class: 'ty_3', name: 'common.fail' },
        };
        const getResponseNameAndStyleByCodeType = (responseCode) => {
            if (responseCode === '0') {
                return REMOTE_UPDATE_RESPONSE_CODE_MAP[REMOTE_UPDATE_RESPONSE_CODE.SUCCESS];
            }
            if (['220', '260', '265', '270'].includes(responseCode)) {
                return REMOTE_UPDATE_RESPONSE_CODE_MAP[REMOTE_UPDATE_RESPONSE_CODE.IN_PROGRESS];
            }
            return REMOTE_UPDATE_RESPONSE_CODE_MAP[REMOTE_UPDATE_RESPONSE_CODE.FAIL];
        };
        return {
            state,
            uuid,
            closePopup,
            getResponseNameAndStyleByCodeType,
        };
    },
});
