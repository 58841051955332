import { defineComponent, computed } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { BANNER_TYPE, BANNER_ICON_MAP } from '@hems/util/src/constant';
export default defineComponent({
    name: 'TitleBanner',
    components: {
        Image2,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        bannerType: {
            type: String,
            default: BANNER_TYPE.DEFAULT,
        },
    },
    setup(props) {
        const iconSrc = computed(() => BANNER_ICON_MAP[props.bannerType]);
        return {
            iconSrc,
        };
    },
});
