import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import * as Highcharts from 'highcharts';
import merge from 'lodash-es/merge';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'HighchartsWrapper',
    props: {
        height: {
            type: String,
            required: false,
            default: '100%',
        },
        width: {
            type: String,
            required: false,
            default: '100%',
        },
        chartOptions: {
            type: Object,
            required: true,
        },
    },
    emits: ['instance'],
    setup(props, { emit }) {
        const instance = ref(null);
        const uuid = Helper.getUUID();
        const requiredOption = {
            credits: {
                enabled: false,
            },
            chart: {
                renderTo: uuid,
            },
        };
        watch(() => props.chartOptions, () => {
            if (!props.chartOptions)
                return;
            if (!instance.value) {
                createChart();
                return;
            }
            instance.value.update(props.chartOptions, true, true, true);
        });
        onMounted(() => {
            createChart();
        });
        function createChart() {
            instance.value = Highcharts.chart(merge(props.chartOptions, requiredOption));
            emit('instance', { instance: instance.value, destroyChart });
        }
        function destroyChart() {
            if (instance.value) {
                instance.value.destroy();
                instance.value = null;
            }
        }
        onBeforeUnmount(destroyChart);
        return {
            chartId: computed(() => uuid),
        };
    },
});
