import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView, useRoute } from 'vue-router';
import { MainTemplate } from '@hems/component';
import { HealthCheckContainer } from '@hems/container';
import { useLanguage } from '@hems/util';
import { useAuthentication } from '@/composables/authentication';
export default defineComponent({
    name: 'App',
    components: {
        MainTemplate,
        HealthCheckContainer,
        RouterView,
    },
    setup() {
        const route = useRoute();
        const { locale: i18nLocale } = useI18n();
        const { languageCode } = useLanguage();
        const { initializeAuthInfo } = useAuthentication();
        const isMobile = computed(() => route.name === 'TermAndConditionPage' ||
            route.name === 'EUTermsAndConditionPage' ||
            route.name === 'AUTermsAndConditionPage' ||
            route.name === 'USTermsAndConditionPage' ||
            route.name === 'NZTermsAndConditionPage' ||
            route.name === 'TermsMobileGen2' ||
            route.name === 'NZTermsMobileGen2');
        // 초기화
        i18nLocale.value = languageCode.value;
        if (!isMobile.value) {
            initializeAuthInfo();
        }
        return {
            languageCode,
            isMobile,
        };
    },
});
