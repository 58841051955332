export const USER_REPORT_TYPE = {
    /** 발전량 (Produced) */
    GENERATION: 'gen',
    /** 소비량 (Consumed) */
    CONSUMPTION: 'con',
    /** 배터리 충전량 (Charged) */
    CHARGE: 'charge',
    /** 배터리 방전량 (Discharged) */
    DISCHARGE: 'discharge',
    /** Grid Export량 (Exported) */
    FEED_IN: 'feedin',
    /** Grid Import량 (Imported) */
    DEMAND: 'demand',
    /** 배터리 SOC */
    SOC: 'soc',
};
export const USER_REPORT_CARD_TYPE = {
    GENERATION: 'generation',
    CONSUMPTION: 'consumption',
    DEMAND: 'demand',
    FEED_IN: 'feedin',
    DISCHARGING: 'discharging',
    CHARGING: 'charging',
};
