import { defineComponent, reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import axios from 'axios';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import pickBy from 'lodash-es/pickBy';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import Image2 from '@hems/component/src/image/Image2.vue';
import { BasicInput, BasicCheckbox, RadioGroup, LabelInput, TruncateInput } from '@hems/component/src/inputs';
import Selector from '@hems/component/src/selectors/Selector/Selector.vue';
import SettingTable from '@hems/component/src/tables/SettingTable.vue';
import SettingTableRow from '@hems/component/src/tables/SettingTableRow.vue';
import Tooltip from '@hems/component/src/tooltip/Tooltip.vue';
import { JoinService } from '@hems/service';
import { Helper, useAppType } from '@hems/util';
const API_BASE_URL = process.env.VUE_APP_API_URL;
export default defineComponent({
    name: 'AccountInfo',
    components: {
        Form,
        SettingTable,
        SettingTableRow,
        BasicInput,
        BasicCheckbox,
        Tooltip,
        RadioGroup,
        LabelInput,
        Selector,
        Image2,
        TruncateInput,
    },
    props: {
        data: {
            type: Object,
            required: false,
            default: null,
        },
    },
    emits: ['save', 'withdrawal', 'openPrivacyPopup'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const { isUserApp } = useAppType();
        let copyData;
        const state = reactive({
            editable: false,
            privacyPopup: false,
            data: omit(props.data, 'password', 'password_confirm'),
            currentEmail: props.data?.email,
        });
        const stateValidationCheck = reactive({
            checkValidation1: false,
            checkValidation2: false,
            checkValidation3: false,
        });
        const telNum = computed(() => `${state.data.local_mpn_no ?? ''} ${state.data.mpn_no ?? ''}`);
        const prevEmail = {
            value: null,
            status: false,
        };
        watch(() => props.data, () => {
            state.data = omit(props.data, 'password', 'password_confirm');
        });
        const schema = yup.object().shape({
            email: yup.string().test('email', { key: 'message.invalid' }, async function (email) {
                const { createError } = this;
                if (Helper.isNull(email)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                if (!yup.string().email().isValidSync(email)) {
                    return createError({
                        message: { key: 'message.email_format' },
                    });
                }
                if (prevEmail.value === email)
                    return prevEmail.status;
                if (!Helper.isNull(state.currentEmail) && state.currentEmail === email)
                    return true;
                try {
                    const userId = state.data.user_id ?? null;
                    const axiosInstance = axios.create({
                        baseURL: API_BASE_URL,
                    });
                    const joinService = new JoinService(axiosInstance);
                    const { result } = await joinService.checkUserIdEmail(email, userId);
                    prevEmail.value = email;
                    prevEmail.status = result.is_valid;
                    return result.is_valid;
                }
                catch (e) {
                    return false;
                }
            }),
            local_mpn_no: yup.string().nullable().required(),
            mpn_no: yup
                .string()
                .test('mpn_no', { key: 'message.invalid_phone_number' }, function (mpn_no) {
                const { createError } = this;
                if (Helper.isNull(mpn_no)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                const regexp = /^[0-9+\-()]{8,20}$/g;
                if (!regexp.test(mpn_no)) {
                    return false;
                }
                return true;
            })
                .nullable()
                .required(),
            password: yup
                .string()
                .test('password', { key: '' }, (password) => {
                stateValidationCheck.checkValidation1 = false;
                stateValidationCheck.checkValidation2 = false;
                stateValidationCheck.checkValidation3 = false;
                if (Helper.isNull(password)) {
                    return true;
                }
                const regexp1 = /^.{8,20}$/;
                const regexp2 = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
                const regexp3 = /.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/;
                if (regexp1.test(password)) {
                    stateValidationCheck.checkValidation1 = true;
                }
                else {
                    stateValidationCheck.checkValidation1 = false;
                }
                if (regexp2.test(password)) {
                    stateValidationCheck.checkValidation2 = true;
                }
                else {
                    stateValidationCheck.checkValidation2 = false;
                }
                if (regexp3.test(password)) {
                    stateValidationCheck.checkValidation3 = true;
                }
                else {
                    stateValidationCheck.checkValidation3 = false;
                }
                if (stateValidationCheck.checkValidation1 === true &&
                    stateValidationCheck.checkValidation2 === true &&
                    stateValidationCheck.checkValidation3 === true) {
                    return true;
                }
                return false;
            })
                .nullable(),
            password_confirm: yup
                .string()
                .nullable()
                .test('password_confirm', { key: 'message.invalid' }, function (password_confirm) {
                const { createError } = this;
                const password = this.parent.password;
                if (!Helper.isNull(password) && Helper.isNull(password_confirm)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                if (password !== password_confirm) {
                    return createError({
                        message: { key: 'message.password_not_match' },
                    });
                }
                return true;
            }),
            user_nm: yup.string().test('user_nm', { key: 'message.invalid' }, function (user_nm) {
                const { createError } = this;
                if (Helper.isNull(user_nm)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                if (user_nm.length < 2) {
                    return createError({
                        message: { key: 'message.too_short' },
                    });
                }
                return true;
            }),
        });
        function onEdit() {
            copyData = cloneDeep(state.data);
            state.editable = true;
        }
        function onCancel() {
            state.data = copyData;
            state.editable = false;
        }
        function onSave() {
            emit('save', pickBy(state.data, (v) => !Helper.isNull(v)));
            state.editable = false;
        }
        const dialSelectOptions = Helper.addSelectorOptionAtFirst(Helper.getDialCodeByCountry().map((item) => ({
            text: `${item.name} ${item.dial_code}`,
            value: item.dial_code,
        })), { text: t('common.select'), value: null });
        return {
            state,
            schema,
            store,
            onEdit,
            onCancel,
            onSave,
            dialSelectOptions,
            telNum,
            stateValidationCheck,
            preventKeyEnter: Helper.preventKeyEnter,
            isUserApp,
        };
    },
});
