import { computed, defineComponent, ref } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
const isLoading = ref(false);
export const withLoading = (apiFunc, close = true) => async (...args) => {
    isLoading.value = true;
    try {
        await apiFunc(...args);
    }
    catch (e) {
        if (close)
            isLoading.value = false;
        throw e;
    }
    if (close)
        isLoading.value = false;
};
export default defineComponent({
    name: 'Loading',
    components: {
        Vue3Lottie,
    },
    setup() {
        const lottieName = location.href.includes('/mobile/') ? 'mobile_loading' : 'loading';
        return {
            isLoading: computed(() => isLoading),
            path: `/resources/lottie/${lottieName}.json`,
            lottieName,
        };
    },
});
