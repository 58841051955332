import { getRoleNm, isExpired, parseJwt, removeAccessToken, saveAccessToken } from '@hems/util/src/helper/authHelper';
export const initState = {
    accessToken: null,
    userId: null,
    userNm: null,
    homeId: null,
    ownDeviceId: null,
    roleCd: null,
    roleNm: null,
};
const userModule = {
    namespaced: true,
    state: { ...initState },
    getters: {
        isAuthenticated: (state) => !!state.accessToken,
        isTokenExpired: (state) => {
            if (!state.accessToken)
                return true;
            const jwtObject = parseJwt(state.accessToken);
            if (!jwtObject) {
                return true;
            }
            return isExpired(jwtObject);
        },
    },
    mutations: {
        clear(state) {
            state.accessToken = null;
            state.userId = null;
            state.userNm = null;
            state.homeId = null;
            state.ownDeviceId = null;
            state.roleCd = null;
            state.roleNm = null;
        },
        setRole(state, payload) {
            state.roleCd = payload.roleCd;
            state.roleNm = payload.roleNm;
        },
        setUserId(state, payload) {
            state.userId = payload;
        },
        setToken(state, payload) {
            state.accessToken = payload;
        },
        removeToken(state) {
            state.accessToken = null;
        },
    },
    actions: {
        setRole({ commit }, payload) {
            commit('setRole', payload);
        },
        setUserId({ commit }, payload) {
            commit('setUserId', payload);
        },
        setToken({ commit }, accessToken) {
            saveAccessToken(accessToken);
            const jwtObject = parseJwt(accessToken);
            if (!jwtObject) {
                return null;
            }
            const userNm = jwtObject.preferred_username;
            const roleCd = jwtObject.auth_type_cd;
            const roleNm = getRoleNm(jwtObject.auth_type_cd);
            commit('setUserId', userNm);
            commit('setRole', { roleCd, roleNm });
            commit('setToken', accessToken);
            window.axiosInstance.setAccessToken(accessToken);
        },
        clear({ commit }) {
            removeAccessToken();
            window.axiosInstance.clearAccessToken();
            commit('clear');
        },
    },
};
export default userModule;
