import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRole } from '@hems/util';
import GeneralAccountInfoPopup from '@/components/popup/accountinfo/general/GeneralAccountInfoPopup.vue';
import InstallerAccountInfoPopup from '@/components/popup/accountinfo/installer/InstallerAccountInfoPopup.vue';
import PrivacyPopup from '@/components/popup/PrivacyPopup.vue';
export default defineComponent({
    name: 'AccountInfoPopup',
    components: {
        GeneralAccountInfoPopup,
        InstallerAccountInfoPopup,
        PrivacyPopup,
    },
    emits: ['close', 'withdrawal'],
    setup() {
        const store = useStore();
        const userInfo = store.state.user;
        const { isUser } = useRole();
        const state = reactive({
            roleNm: computed(() => userInfo.roleNm),
            privacyPopup: false,
        });
        function openPrivacyPopup() {
            state.privacyPopup = true;
        }
        function closePrivacyPopup() {
            state.privacyPopup = false;
        }
        return {
            state,
            openPrivacyPopup,
            closePrivacyPopup,
            isUser,
        };
    },
});
