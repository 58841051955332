import { checkUserAgent } from '@hems/util/src/helper/mapper/smartmoduleHelperPro';
export function webToApp(params) {
    if (checkUserAgent() === 'ios') {
        console.log('OS === IOS'); // to Native IOS
        if (window.webToAppCall) {
            console.log('APP TYPE : IOS HOME APP'); // home ios
            window.webToAppCall.postMessage(JSON.stringify(params));
        }
        if (window.webkit?.messageHandlers?.webViewMessageHandler) {
            console.log('APP TYPE : IOS PRO APP'); // pro ios
            window.webkit.messageHandlers.webViewMessageHandler.postMessage(JSON.stringify(params));
        }
        else {
            if (window.webkit) {
                console.log(`[WEB] webkit : ${window.webkit}`);
                console.log(`[WEB] messageHandlers : ${window.webkit.messageHandlers}`);
            }
        }
    }
    else {
        console.log('OS === AOS or PC'); // to Android
        if (window.webToAppCall) {
            console.log('APP TYPE : AOS HOME APP'); // home android
            window.webToAppCall.postMessage(JSON.stringify(params));
        }
        if (window.webToProAppCall) {
            console.log('APP TYPE : AOS PRO APP'); // pro android
            window.webToProAppCall.postMessage(JSON.stringify(params));
        }
    }
}
export function addEventListener(name, fn) {
    if (!window.appToWebCall) {
        window.appToWebCall = {};
    }
    if (!window.appToWebCall[name]) {
        window.appToWebCall[name] = fn;
    }
}
export function removeEventListener(name) {
    if (!window.appToWebCall)
        return;
    if (!window.appToWebCall[name])
        return;
    delete window.appToWebCall[name];
}
